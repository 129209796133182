export const LocationIcon = (color: any = "#000099", size: number | string = 30) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 33C17.7832 33.0052 0.907518 8.00199 20 8C39.0925 7.99801 22.2168 32.9948 20 33ZM16.8088 17.0971C17.3073 14.7303 17.9675 13.9398 20 13.7278C22.2609 14.1801 22.9602 14.8922 23.0522 17.0971C22.6955 18.6583 21.9612 19.2583 20 19.9946C18.2663 19.5097 17.4191 19.0724 16.8088 17.0971Z"
      fill="white"
    />
  </svg>
);
