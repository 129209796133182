import theme from "../../theme";

interface Props {
  color?: string;
  size?: number | string;
}
export const StatusIcon = ({color, size}: Props) => {
  const defaultColor = theme.palette.grey[500]
  const defaultSize = 24;
  return (
    <svg width={size ?? defaultSize} height={size ?? defaultSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="5" fill={color ?? defaultColor} />
    </svg>
  );
};
