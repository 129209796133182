import { Box } from "@mui/material";
import { StatusIcon } from "../../../assets/icons";
import { colors } from "../../../theme";
import DashboardListElement from "./DashboardListElement";
import { useFaultReportSummary } from "../../../dataQuery/faultReportsApi/useFaultReportSummary";
import { useAppNavigate } from "../../../hooks/navigation/useAppNavigate";
import { AppRoute } from "../../../hooks/navigation/routes";

const DashboardList = (): JSX.Element | null => {
  const navigate = useAppNavigate();
  const { data: reportsSummary, isLoading } = useFaultReportSummary();

  return (
    <Box>
      <DashboardListElement
        icon={<StatusIcon color={colors.status.approvalPending} />}
        label={"Freigabe von Kommune erforderlich"}
        count={reportsSummary?.approvalPending}
        onClick={() => navigate(AppRoute.INAPPROVALVIEW)}
        isLoading={isLoading}
      />
      <DashboardListElement
        icon={<StatusIcon color={colors.status.inProgress} />}
        label={"Störungen in Bearbeitung"}
        count={reportsSummary?.inProgress}
        onClick={() => navigate(AppRoute.INPROGRESSVIEW)}
        isLoading={isLoading}
      />
      <DashboardListElement
        icon={<StatusIcon color={colors.status.completed} />}
        label={"Störungen geschlossen"}
        count={reportsSummary?.completed}
        onClick={() => navigate(AppRoute.COMPLETEDVIEW)}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default DashboardList;
