import { useQuery } from "react-query";
import { useAppContext } from "../../context";
import { FaultReportErrorType } from "../../context/ErrorContext/LionError";
import { useApiClient } from "../apiClient/apiClient";
import { FaultReportsApiGetSummaryResult } from "./faultReportsApi.types";

export function useFaultReportSummary() {
  const { customerScope, isOnMaintenanceRef } = useAppContext();
  const apiClient = useApiClient();

  return useQuery(
    "faultreport_summary",
    () =>
      apiClient.get<FaultReportsApiGetSummaryResult>(
        `v2/faultreports/summary?customerScope=${customerScope}`,
        FaultReportErrorType.FAULTREPORT_GET
      ),
    {
      enabled: !!customerScope && !isOnMaintenanceRef.current,
    }
  );
}
