import { Button } from "@mui/material";
import PlusIcon from "@mui/icons-material/Add";
import { useAppNavigate } from "../hooks/navigation/useAppNavigate";
import { AppRoute } from "../hooks/navigation/routes";

export const NewFaultReportButton = () => {
  const navigate = useAppNavigate();
  return (
    <Button variant="contained" color="secondary" onClick={() => navigate(AppRoute.CREATE_REPORT)}>
      <PlusIcon /> NEUE STÖRUNGSMELDUNG
    </Button>
  );
};
