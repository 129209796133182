import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GoogleAddressSearchContextProviderProps, GoogleAddressSearchContextValue, LatLng } from ".";
import { useJsApiLoader } from "@react-google-maps/api";

const GEOCODER_COMPONENT_RESTRICTION = { country: "de" };

const initState:GoogleAddressSearchContextValue = {
  googleApiIsLoaded: false,
  searchCenter:  { lat: 0, lng: 0 },
  setSearchCenter: () => { }
};

export const GoogleAddressSearchContext = React.createContext<GoogleAddressSearchContextValue>(initState);


export const GoogleAddressSearchProvider = (props: GoogleAddressSearchContextProviderProps) => {
  const [searchCenter, setSearchCenter] = useState<LatLng>();
  const [locality, setLocality] = useState<string>();
  const autocompleteService = useRef<google.maps.places.AutocompleteService>();
  const geocoder = useRef<google.maps.Geocoder>();
  
  const { isLoaded: googleApiIsLoaded } = useJsApiLoader({
    googleMapsApiKey: props.googleMapsApiKey,
    libraries: props.googleMapslibraries,
  });
  const getCitiesFromGeocoderResponce = useCallback(
    (resp: google.maps.GeocoderResponse) =>
      resp.results
        .map((r) => r.address_components.filter((a) => a.types.includes("administrative_area_level_4")))
        .filter((a) => a.length > 0),
    []
  );

  const findAndSetLocality = useCallback(() => {
    if (!searchCenter || !googleApiIsLoaded) return;
    const geocoder = new google.maps.Geocoder();
    const { lat, lng } = searchCenter;
    geocoder
      .geocode({
        location: new google.maps.LatLng({ lat, lng }),
      })
      .then((res) => {
        const citiesList = getCitiesFromGeocoderResponce(res);
        const firstCity = citiesList.shift()?.shift()?.long_name;
        setLocality(firstCity);
      });
  }, [googleApiIsLoaded, getCitiesFromGeocoderResponce, searchCenter]);

  useEffect(() => {
    if (!googleApiIsLoaded) return;
    autocompleteService.current = new google.maps.places.AutocompleteService();
    geocoder.current = new google.maps.Geocoder();
  }, [googleApiIsLoaded]);

  useEffect(() => {
    findAndSetLocality();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCenter]);

  const getCoodinatesOfAddress = useCallback(async (address: string) => {
    // if (!Utils.hasText(address)) return;
    if (!geocoder.current) {
      console.error("Geocoder is not instantiated");
      return;
    }
    try {
      const geocoderResponce = await geocoder.current.geocode({ address });
      const geocoderResult = geocoderResponce.results.shift();
      if (!geocoderResult) {
        console.error("No geographical information could be retrieved for the given address", address);
        return;
      }
      return { lat: geocoderResult.geometry.location.lat(), lng: geocoderResult.geometry.location.lng() };
    } catch (err) {
      console.error(err);
    }
  }, []);

  const searchAddress = useMemo(
    () => async (address: string): Promise<string[] | undefined> => {
      if (!address) {
        return;
      }

      if (!autocompleteService?.current) return;
      const input = locality ? locality + ", " + address : address;
      const request: google.maps.places.AutocompletionRequest = {
        input,
        componentRestrictions: GEOCODER_COMPONENT_RESTRICTION,
        types: ["address"],
      };

      const result = await autocompleteService.current.getPlacePredictions(request);
      return result.predictions?.map<string>((prediction) => {
        return prediction.description;
      });
    },
    [locality, autocompleteService]
  );


  const contextValue: GoogleAddressSearchContextValue = useMemo(
    () => ({
      googleApiIsLoaded,
      locality,
      searchCenter,
      setSearchCenter,
      searchAddress,
      getCoodinatesOfAddress,
    }),
    [googleApiIsLoaded, locality, searchCenter, setSearchCenter, searchAddress, getCoodinatesOfAddress]
  );

  return (
    <GoogleAddressSearchContext.Provider value={contextValue}>{props.children}</GoogleAddressSearchContext.Provider>
  );
};

export const useGoogleAddressSearch = () => {
  return React.useContext(GoogleAddressSearchContext);
};
