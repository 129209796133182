export const ApproveCitizenIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.671143 12C0.671143 5.37258 6.04373 0 12.6711 0V0C19.2986 0 24.6711 5.37258 24.6711 12V12C24.6711 18.6274 19.2986 24 12.6711 24V24C6.04373 24 0.671143 18.6274 0.671143 12V12Z"
      fill="url(#paint0_linear_2486_32967)"
    />
    <path
      d="M17.1291 12.588C17.1531 12.396 17.1711 12.204 17.1711 12C17.1711 11.796 17.1531 11.604 17.1291 11.412L18.3951 10.422C18.5091 10.332 18.5391 10.17 18.4671 10.038L17.2671 7.962C17.1951 7.83 17.0331 7.782 16.9011 7.83L15.4071 8.43C15.0951 8.19 14.7591 7.992 14.3931 7.842L14.1651 6.252C14.1471 6.108 14.0211 6 13.8711 6H11.4711C11.3211 6 11.1951 6.108 11.1771 6.252L10.9491 7.842C10.5831 7.992 10.2471 8.196 9.9351 8.43L8.44109 7.83C8.30309 7.776 8.1471 7.83 8.0751 7.962L6.8751 10.038C6.7971 10.17 6.8331 10.332 6.9471 10.422L8.2131 11.412C8.1891 11.604 8.1711 11.802 8.1711 12C8.1711 12.198 8.1891 12.396 8.2131 12.588L6.9471 13.578C6.8331 13.668 6.8031 13.83 6.8751 13.962L8.0751 16.038C8.1471 16.17 8.30909 16.218 8.44109 16.17L9.9351 15.57C10.2471 15.81 10.5831 16.008 10.9491 16.158L11.1771 17.748C11.1951 17.892 11.3211 18 11.4711 18H13.8711C14.0211 18 14.1471 17.892 14.1651 17.748L14.3931 16.158C14.7591 16.008 15.0951 15.804 15.4071 15.57L16.9011 16.17C17.0391 16.224 17.1951 16.17 17.2671 16.038L18.4671 13.962C18.5391 13.83 18.5091 13.668 18.3951 13.578L17.1291 12.588V12.588ZM12.6711 14.1C11.5131 14.1 10.5711 13.158 10.5711 12C10.5711 10.842 11.5131 9.9 12.6711 9.9C13.8291 9.9 14.7711 10.842 14.7711 12C14.7711 13.158 13.8291 14.1 12.6711 14.1Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2486_32967"
        x1="0.671143"
        y1="0"
        x2="0.671143"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9900" />
        <stop offset="1" stopColor="#EE7700" />
      </linearGradient>
    </defs>
  </svg>
);
