import { useAppContext } from "../../context";
import { ImageApi, ImageIO, StoredImageInfo } from "./imageApi.types";
import { useApiClient } from "../apiClient/apiClient";
import { ImageErrorType } from "../../context/ErrorContext/LionError";

const getItemPath = (id: string, customerScope?: string) =>
  `v2/faultreports/${id}/documents?customerScope=${customerScope || ""}`;

export function useImageApi(): ImageApi {
  const apiClient = useApiClient();
  const { customerScope } = useAppContext();

  return {
    post: async (reportId, images: ImageIO[]): Promise<StoredImageInfo[]> => {
      if (!images.length) {
        return [];
      }
      const url = getItemPath(reportId, customerScope);
      return apiClient.post<StoredImageInfo[], ImageIO[]>(url, images, ImageErrorType.IMAGE_UPLOAD);
    },
  };
}
