import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { colors } from "../../theme";

interface FaultReportDialogProps {
  open: boolean;
  onNewReportClick: () => void;
  onSaveDialogClose: () => void;
}

export const FaultReportSuccessDialog = (props: FaultReportDialogProps) => {
  return (
    <Dialog open={props.open}>
      <DialogTitle>
        <Typography>Ihre Meldung wurde erfolgreich gespeichert!</Typography>
      </DialogTitle>
      <DialogContent className="flex flex-col items-center">
        <CheckCircleOutlineIcon fontSize="large" htmlColor={colors.success.main} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={props.onNewReportClick}>
          Neue Meldung erfassen
        </Button>
        <Button color="secondary" variant="contained" onClick={props.onSaveDialogClose}>
          Zur Startseite
        </Button>
      </DialogActions>
    </Dialog>
  );
};
