import { ErrorContent, LionErrorType } from "./LionError";

const unspecificErrorContent: ErrorContent = {
  title: "Ein Fehler ist aufgetreten",
  message: "Bitte versuchen Sie später noch einmal.",
};

const emptyErrorContent: ErrorContent = {
  title: "",
  message: "",
};

export const defaultErrorContents: { [k in LionErrorType]: ErrorContent } = {
  CRITICAL: emptyErrorContent,
  UNCRITICAL: unspecificErrorContent,
  MAINTENANCE: {
    title: "",
    message: "Die Anwendung wird gerade gewartet.",
  },
  UNSPECIFIED: emptyErrorContent,
  FAULTREPORT_CREATE: {
    title: "Ihre Meldung konnte leider nicht gespeichert werden",
    message: unspecificErrorContent.message,
  },
  FAULTREPORT_GET: {
    title: "Fehler beim Laden der Störungsmeldungen aufgetreten",
    message: "Einige oder alle Störungsmeldungen konnten nicht geladen werden.",
  },
  REFETCH_LIGHTPOINT: {
    title: "Problem beim Laden der bearbeiteten Leuchtstelle",
    message:
      "Die bearbeitete Leuchtstelle konnte nicht neu geladen werden, daher wird sie noch im alten Zustand angezeigt.",
  },
  FETCH_LIGHTPOINTS: {
    title: "Problem beim Laden der bearbeiteten Leuchtstelle",
    message:
        "Die bearbeitete Leuchtstelle konnte nicht neu geladen werden, daher wird sie noch im alten Zustand angezeigt.",
  },
  IMAGE_UPLOAD: {
    title: "Fehler beim Hochladen der Bilder",
    message: "Leider konnten Ihre Bilder nicht hochgeladen werden.",
  },
};
