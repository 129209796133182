import { Card, Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { FireIcon } from "../assets/icons";
import { colors } from "../theme";

interface Props {
  variant?: "outlined" | "elevation";
  customContent?: ReactNode;
  style?: React.CSSProperties;
  detailedContent?: boolean;
}

export const EmergencyHintBox = (props: Props) => {
  return (
    <Card
      variant={props.variant || "outlined"}
      className="p-3 flex border-2 border-solid border-red-600"
      style={props.style}
    >
      <Box>
        <Box className="flex flex-row items-center">
          <Box marginRight="10px">{FireIcon()}</Box>
          <Box>
            <Typography className="font-bold mb-2 text-center text-xl">IM NOTFALL / GEFAHR IN VERZUG</Typography>
          </Box>
        </Box>
        <Box>
          {props.customContent ? (
            props.customContent
          ) : (
            <Typography>
              Bei Störungen in der Straßenbeleuchtung, die eine Gefahr darstellen, wählen Sie bitte umgehend unsere
              Hotline
            </Typography>
          )}
          <Typography marginTop={"5px"}>
            <a
              href="tel:+498003629477"
              style={{ color: colors.text.primary, textDecoration: "none", fontWeight: "bold" }}
            >
              0800/3629 477
            </a>
          </Typography>
        </Box>
        {props.detailedContent && (
          <>
            <br />
            <Typography style={{ fontSize: 14 }}>
              <strong>Wann liegt eine Gefahr vor?</strong>
            </Typography>
            <ul>
              <Typography style={{ fontSize: 14 }}>
                <li>Alle oder mehrere Leuchten in derselben Straße oder auf einer Kreuzung sind ausgefallen</li>
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                <li>Ein Fußgängerüberweg ist nicht mehr beleuchtet</li>
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                <li>Ein Straßenbeleuchtungsmast oder Schaltschrank wurde um-/angefahren</li>
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                <li>Die Tür an einem Beleuchtungsmast ist offen und/oder Kabel liegen frei</li>
              </Typography>
            </ul>
          </>
        )}
      </Box>
    </Card>
  );
};
