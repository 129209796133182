import React from "react";

export const ArrowIcon = () => {
  return (
    <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line
        y1="-1.5"
        x2="12.7018"
        y2="-1.5"
        transform="matrix(-0.682319 -0.731054 0.682319 -0.731054 11 9.28564)"
        stroke="#828282"
        strokeWidth="3"
      />
      <line
        y1="-1.5"
        x2="14.6559"
        y2="-1.5"
        transform="matrix(-0.682318 0.731055 -0.682318 -0.731055 11 9.28564)"
        stroke="#828282"
        strokeWidth="3"
      />
    </svg>
  );
};
