export enum ReporterType {
  CITIZEN = "CITIZEN",
  MUNICIPALITY = "MUNICIPALITY",
  INTERN = "INTERN",
  COMPANY = "COMPANY",
  OTHER = "OTHER",
}

export const getReporterTypeLabel = (reporterType: ReporterType): string => {
  switch (reporterType) {
    case ReporterType.CITIZEN:
      return "Bürger";
    case ReporterType.OTHER:
    case ReporterType.INTERN:
    case ReporterType.COMPANY:
      return "Netze BW";
    case ReporterType.MUNICIPALITY:
      return "Kommune";
    default:
      return "";
  }
};
