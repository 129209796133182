import { FetchLightPointsQuery, GetLightPointsResponse } from "./lightPointsApi.types";
import { createURLSearchParams } from "../apiParamFactory";
import { useApiClient } from "../apiClient/apiClient";
import { useAppContext } from "../../context";
import { LightPointErrorType, LionErrorType } from "../../context/ErrorContext/LionError";

export const useLightPointsApi = () => {
  const apiClient = useApiClient();
  const { customerScope } = useAppContext();

  async function getLightPointsByBounds(
    query?: FetchLightPointsQuery,
    errorType?: LionErrorType
  ): Promise<GetLightPointsResponse> {
    const searchParams = createURLSearchParams(query, customerScope);
    return apiClient.get<GetLightPointsResponse>(
      `v2/lightingpoints/by-bounds?${searchParams.toString()}`,
      errorType || LightPointErrorType.FETCH_LIGHTPOINTS
    );
  }

  const searchLightPoints = (lightingNo?: string) =>
    apiClient.get<GetLightPointsResponse>(
      "v2/lightingpoints/search?" + createURLSearchParams({ lightingNo }, customerScope).toString(),
      LightPointErrorType.FETCH_LIGHTPOINTS
    );

  const fetchAllLightPointsAsGeoJson = () =>
    apiClient.get<any>(
      "v2/lightingpoints/geo-json?customerScope=" + customerScope,
      LightPointErrorType.FETCH_LIGHTPOINTS
    );
  

  return {
    getLightPointsByBounds,
    searchLightPoints,
    fetchAllLightPointsAsGeoJson
  };
};
