import { Box, Container, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../context";
import theme from "../theme";

export function Footer() {
  const { isHeadless } = useContext(AppContext);

  if (isHeadless) return null;

  return (
    <Box
      style={{
        backgroundColor: theme.palette.grey[800],
        height: "3em",
        paddingTop: "2.5em",
        bottom: 0,
      }}
    >
      <Container>
        <Grid container>
          <Grid item md={4}>
            <Typography style={{ color: theme.palette.grey[500] }}>
              {"<"} Zur Netze BW Website | Datenschutz | Impressum{" "}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography align="center" style={{ color: theme.palette.grey[500] }}>
              Feedback zur Anwendung geben
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography align="right" style={{ color: theme.palette.grey[500] }}>
              (C) Netze BW GmbH 2020 Alle Rechte vorbehalten
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
