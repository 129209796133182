import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { useAppContext } from "../../context";
import { baseUrl, xApiKey } from "../paths";

export const useAxios = () => {
  const { keycloak } = useKeycloak();
  const { environment } = useAppContext();

  const axiosInstance = axios.create({
    baseURL: baseUrl[environment],
    headers: {
      "x-api-key": xApiKey,
      authorization: `Bearer ${keycloak.token}`,
      "content-type": "application/json",
    },
  });

  axiosInstance.interceptors.request.use((config: any) => {
    config.headers.authorization = "Bearer " + keycloak.token;
    return config;
  });

  return axiosInstance;
};
