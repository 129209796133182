import { Box } from "@mui/material";
import React from "react";
import theme from "../theme";

export function Main({
  children,
  containerStyle,
}: {
  children?: React.ReactNode;
  containerStyle?: React.CSSProperties;
}) {
  return (
    <Box
      maxWidth="1300px"
      style={{
        backgroundColor: theme.palette.grey[200],
        marginTop: "2em",
        minHeight: "500px",
        marginBottom: "2em",
        paddingRight: "20px",
        flex: 1,
        ...containerStyle,
      }}
      component="main"
    >
      {children}
    </Box>
  );
}
