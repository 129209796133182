import { Symptom, SYMPTOM_LABEL } from "../../models";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useEffect } from "react";
import { EmergencyHintBox } from "../../parts";

export interface EmergencyDialogProps {
  open: boolean;
  onClose: () => void;
  emergencySymptoms: Symptom[];
}

export const EmergencyDialog = (props: EmergencyDialogProps) => {
  useEffect(() => {
    return () => {
      props.onClose();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <EmergencyHintBox customContent={renderEmergencyContent(props.emergencySymptoms)} variant="outlined" />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={props.onClose}>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const notAllowedSymptopmContent = (es: Symptom[]) =>
  es.map((s, i) => {
    const label = SYMPTOM_LABEL[s];
    if (!label) return <></>;
    const key = label + i;
    let element: JSX.Element = <b key={key}>"{label}"</b>;
    const nextTolastElementIndex = es.length - 2;
    if (i < nextTolastElementIndex) {
      return <span key={key}>{element}, </span>;
    } else if (i > nextTolastElementIndex) {
      return element;
    } else {
      return <span key={key}>{element} und </span>;
    }
  });

const renderEmergencyContent = (es: Symptom[]) => (
  <>
    <Typography>
      Für die Symptome {notAllowedSymptopmContent(es)} ist ein Melden über das System <b>nicht</b> möglich.
    </Typography>
    <Typography marginTop="5px">
      Bitte gehen Sie für eine schnellstmögliche Bearbeitung Ihres Anliegens direkt auf die Leitstelle zu. Dadurch
      gewährleisten wir Ihnen eine zeitnahe Bearbeitung, außerhalb der Reparaturfahrten.
    </Typography>
  </>
);
