import { Button, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { colors } from "../../../theme";
import CitizenReportDialog from "./CitizenReportDialog";
import { useState } from "react";
import { IncidentStatus } from "../../../models/IncidentStatus";

interface Props {
  onSubmit: (approved: boolean, remarks?: string) => void;
  incidentStatus?: IncidentStatus;
}
export const ApproveCitizenReportBox = ({ onSubmit, incidentStatus }: Props) => {
  const [variant, setVariant] = useState<"approve" | "reject">("approve");
  const [open, setOpen] = useState(false);

  const handleOpenModal = (variant: "approve" | "reject") => {
    setVariant(variant);
    setOpen(true);
  };

  const handleOnSubmit = (approved: boolean, remarks?: string) => {
    setOpen(false);
    onSubmit(approved, remarks);
  };

  return incidentStatus !== IncidentStatus.ApprovalRequiredByCommune ? null : (
    <Box
      className="w-full flex justify-center items-center"
      border={`1px solid ${colors.secondary.main}`}
      style={{ backgroundColor: "rgb(255, 153,0,0.1)" }}
    >
      <Paper elevation={0} className="bg-transparent m-5">
        <Typography className="mb-5">
          <strong>Möchten Sie diese Störungsmeldung zur Bearbeitung an Netze BW freigeben?</strong>
        </Typography>
        <Box className="flex justify-center items-center">
          <Button
            variant="contained"
            className="w-52 bg-white mr-2"
            style={{
              border: `1px solid ${colors.secondary.main}`,
              color: colors.secondary.main,
            }}
            onClick={() => handleOpenModal("reject")}
          >
            NEIN, ABLEHNEN
          </Button>
          <Button variant="contained" color="secondary" className="w-52" onClick={() => handleOpenModal("approve")}>
            JA, MELDUNG FREIGEBEN
          </Button>
        </Box>
      </Paper>
      <CitizenReportDialog open={open} onClose={() => setOpen(false)} onSubmit={handleOnSubmit} variant={variant} />
    </Box>
  );
};

export default ApproveCitizenReportBox;
