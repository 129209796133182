export interface IConfiguration {
  enersisApiKey: string;
}

const defaultConfiguration: IConfiguration = {
  enersisApiKey: "SET_BY_PIPELINE",
};

export function getConfiguration(): IConfiguration {
  return {
    enersisApiKey: process.env.REACT_APP_ENERSIS_API_KEY || defaultConfiguration.enersisApiKey,
  };
}
