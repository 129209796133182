import format from "date-fns/fp/formatWithOptions";
import { de } from "date-fns/locale";
import { MaintenanceReport } from "../models/MaintenanceReport";
import { isAfter, isBefore, isEqual } from "date-fns";

export namespace Utils {
  export const formatToLocalDate = format({ locale: de }, "dd.MM.yyyy HH:mm");

  export const hasText = (t: string) => typeof t == "string" && t.trim().length > 0;

  export function getCurrentMaintenance(reports: MaintenanceReport[], currentDateTime: Date) {
    for (const report of reports) {
      const startDate = new Date(report.startDateTime);
      const endDate = new Date(report.endDateTime);
      if (isInPeriodOfTime(currentDateTime, startDate, endDate)) {
        return report;
      }
    }
    return undefined;
  }
}

function isInPeriodOfTime(comparingDate: Date, start: Date, end: Date) {
  return (
    isEqual(comparingDate, start) ||
    isEqual(comparingDate, end) ||
    (isAfter(comparingDate, start) && isBefore(comparingDate, end))
  );
}
