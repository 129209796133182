import { Box, Typography } from "@mui/material";
import React from "react";
import { CreateFaultReportButton } from "../GeoSearchBar";
import { StepperBar } from "../StepperBar";

interface StepperProps {
  goBackBtnDesabled?: boolean;
  activeStep: number;
  title?: string;
  steps: StepInfo[];
  isNextButtonDisabled?: () => boolean;
  onCancel?: () => void;
  style?: React.CSSProperties;
  submitButtonStyle?: React.CSSProperties;
  onActiveStepChange: (i: number) => void;
  submitStepIndex?: number;
}

interface StepInfo {
  label: string;
  icon: JSX.Element;
  content: JSX.Element;
  submitBtnLabel: string;
  onSubmit?: () => void;
}

export function Stepper({ activeStep, ...props}: StepperProps) {
  const stepBarSteps = props.steps.map(s => ({ label: s.label, icon: s.icon }));

  function handlePrevClick() {
    if (props.onCancel) {
      if (activeStep === 0) {
        props.onCancel();
        return;
      }
    }
    props.onActiveStepChange(activeStep - 1);
  }

  function handleClickNext() {
    if (activeStep < props.steps.length - 1) {
      props.onActiveStepChange(activeStep + 1);
    }
    props.steps[activeStep].onSubmit?.();
  }

  return (
    <>
      <Typography variant="h2" style={{ marginBottom: 20 }}>
        {props.title}
      </Typography>
      <Box style={props.style}>
        <StepperBar 
          index={activeStep}
          steps={stepBarSteps}
        />
        {props.steps[activeStep].content}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CreateFaultReportButton
            sx={{ marginY: "10px" }}
            style={props.submitButtonStyle}
            disabled={props?.isNextButtonDisabled?.()}
            color="secondary"
            onClick={handleClickNext}
          >
            {props.steps[activeStep].submitBtnLabel}
          </CreateFaultReportButton>
          <CreateFaultReportButton disabled={props.goBackBtnDesabled} color="secondary" variant="text" onClick={handlePrevClick}>
            {activeStep === 0 ? "Abbrechen" : "Zurück"}
          </CreateFaultReportButton>
        </Box>
      </Box>
    </>
  );
}