import { Box, Typography } from "@mui/material";
import { Main } from "../../layout";
import { FaultReportsFilter } from "./FaultReportsFilter";
import { FaultReportsTable } from "./FaultReportsTable";
import { NewFaultReportButton } from "../../parts/NewFaultReportButton";
import { useEffect, useState } from "react";
import { useFaultReportContext } from "../../context/FaultReportContext";
import { FaultStateVariant } from "../../hooks/navigation/routes";
import { GetFaultReportsResponse, useFaultReportsApi } from "../../dataQuery";

interface FaultReportListProps {
  variant: FaultStateVariant;
}

export function FaultReportList({ variant }: Readonly<FaultReportListProps>) {
  const { currentFilter } = useFaultReportContext();
  const {
    getInProgressFaultReports,
    getCompletedFaultReports,
    getAllFaultReports,
    getFaultReportsToApprove,
  } = useFaultReportsApi();
  const [faultReportsList, setFaultReportsList] = useState<GetFaultReportsResponse>();

  useEffect(() => {
    // reset incidentStatus filter when switching between views
    currentFilter.incidentStatus = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant]);

  useEffect(() => {
    if (variant === FaultStateVariant.COMPLETEDVIEW) {
      getCompletedFaultReports(currentFilter).then((response) => {
        setFaultReportsList(response);
      });
    } else if (variant === FaultStateVariant.INPROGRESSVIEW) {
      getInProgressFaultReports(currentFilter).then((response) => {
        setFaultReportsList(response);
      });
    } else if (variant === FaultStateVariant.INAPPROVALVIEW) {
      getFaultReportsToApprove(currentFilter).then((response) => {
        setFaultReportsList(response);
      });
    } else {
      getAllFaultReports(currentFilter).then((response) => {
        setFaultReportsList(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter, setFaultReportsList, variant]);

  return (
    <Main>
      <Box className="flex justify-between items-center pb-8">
        <Typography variant="h2">{title[variant]}</Typography>
        <NewFaultReportButton />
      </Box>
      <FaultReportsFilter variant={variant} />
      <FaultReportsTable variant={variant} faultReports={faultReportsList} />
    </Main>
  );
}

const title: Record<FaultStateVariant, string> = {
  INPROGRESSVIEW: "Störungen in Bearbeitung",
  INAPPROVALVIEW: "Störungsmeldungen zur Freigabe",
  COMPLETEDVIEW: "Archivierte Störungsmeldungen",
};
