import { Typography } from "@mui/material";
import React from "react";

interface Props {
  label: string;
  children: React.ReactNode;
}

export const InfoCardSection = ({ label, children }: Props) => {
  return (
    <Typography component="span">
      <div style={containerStyle}>
        <h5 style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>{label}</h5>
        {children}
      </div>
    </Typography>
  );
};

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
};
