import React, { useEffect, useMemo, useRef, useState } from "react";
import { GetLightPointsResponse, useLightPointsApi } from "../../dataQuery/lightPointsApi";
import { EnersisGeoMapContextProviderProps, EnersisGeoMapContextValue } from "./EnersisGeoMapContext.types";
import { CoordinatesAndZoom, GeoJsonLightpointFeatureCollection, LightpointProperties } from "../../components/GeoMapEnersis/GeoMap.types";

const initState: EnersisGeoMapContextValue = {
  mapRef: { current: undefined },
  apiKey: "",
  setSelectedLightPoint: () => {},
  setLightPoints: () => {},
  setCenter: () => {},
  loadLightpoints: async () => {
    return undefined;
  },
  mapIsLoading: false,
  setMapIsLoading: () => {},
};

export const EnersisGeoMapContext = React.createContext<EnersisGeoMapContextValue>(initState);

export const EnersisGeoMapProvider = (props: EnersisGeoMapContextProviderProps) => {
  const [selectedLightPoint, setSelectedLightPoint] = useState<LightpointProperties>();
  const [lightPoints, setLightPoints] = useState<GeoJsonLightpointFeatureCollection>();
  const [center, setCenter] = useState<CoordinatesAndZoom>();
  const [mapIsLoading, setMapIsLoading] = useState<boolean>(true);
  const mapRef = useRef<google.maps.Map>();
  const { fetchAllLightPointsAsGeoJson, searchLightPoints } = useLightPointsApi();

  const loadLightpoints = useMemo(
    () => async (): Promise<void> => {
      setMapIsLoading(true);

      fetchAllLightPointsAsGeoJson()
        .then((result) => {
          setLightPoints(result as GeoJsonLightpointFeatureCollection);
        })
        .finally(() => setMapIsLoading(false));
    },
    [fetchAllLightPointsAsGeoJson]
  );

  const searchLightPointsWithNumber = useMemo(
    () => async (lightpointNumber: string): Promise<GetLightPointsResponse> => {
      setMapIsLoading(true);

      return searchLightPoints(lightpointNumber).finally(() => setMapIsLoading(false));
    },
    [searchLightPoints]
  );

  useEffect(() => {
    console.log(selectedLightPoint);
  }, [selectedLightPoint]);

  const contextValue: EnersisGeoMapContextValue = useMemo(
    () => ({
      apiKey: props.apiKey,
      mapRef,
      fetchAllLightPointsAsGeoJson,
      selectedLightPoint,
      setSelectedLightPoint,
      lightPoints,
      setLightPoints,
      loadLightpoints,
      mapIsLoading,
      setMapIsLoading,
      setCenter,
      center,
      searchLightPointsWithNumber,
    }),
    [
      props.apiKey,
      selectedLightPoint,
      setSelectedLightPoint,
      setLightPoints,
      lightPoints,
      setCenter,
      center,
      fetchAllLightPointsAsGeoJson,
      loadLightpoints,
      mapIsLoading,
      setMapIsLoading,
      searchLightPointsWithNumber,
    ]
  );

  return <EnersisGeoMapContext.Provider value={contextValue}>{props.children}</EnersisGeoMapContext.Provider>;
};

export const useEnersisGeoMap = () => {
  return React.useContext(EnersisGeoMapContext);
};
