import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { FaultUtils } from "../../utils/faultReportUtils";
import { FaultReportContextValue, FaultReportFilter } from "./FaultReportContext.types";

const initValue: FaultReportContextValue = {
  currentFilter: {
    orderBy: "reportingTimestamp",
    orderDir: "DESC",
    perPage: 10,
    page: 0,
    incidentStatus: [],
  },
  updateFilter: (_) => {},
  setNewFilter: (_) => {},
};

const FaultReportContext = createContext(initValue);

export function FaultReportContextProvider({ children }: { children: ReactNode }) {
  const [currentFilter, setCurrentFilter] = useState(initValue.currentFilter);

  const value: FaultReportContextValue = useMemo(
    () => ({
      currentFilter,
      setNewFilter: setCurrentFilter,
      updateFilter: (f: Partial<FaultReportFilter>) => {
        setCurrentFilter((cf) => ({
          ...cf,
          ...f,
          page: FaultUtils.getPageForNewFilter(cf, f, initValue.currentFilter.page),
        }));
      },
    }),
    [currentFilter]
  );

  return <FaultReportContext.Provider value={value}>{children}</FaultReportContext.Provider>;
}

export const useFaultReportContext = () => useContext(FaultReportContext);
