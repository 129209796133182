export const RejectCitizenIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.671143 12C0.671143 5.37258 6.04373 0 12.6711 0C19.2986 0 24.6711 5.37258 24.6711 12C24.6711 18.6274 19.2986 24 12.6711 24C6.04373 24 0.671143 18.6274 0.671143 12Z"
      fill="url(#paint0_linear_2486_33994)"
    />
    <path
      d="M6.83781 8.50048H9.07081C8.29206 10.1951 8.29206 13.8065 9.07081 15.5005H6.83781C6.07306 14.8541 5.67114 13.4291 5.67114 12.0034C5.67114 10.5742 6.07481 9.14506 6.83781 8.50048ZM19.6711 12.0034C19.6741 16.6881 17.8366 19.5074 16.1006 18.9241C15.6491 18.7724 10.5198 15.6696 10.5198 15.6696C9.35723 14.3746 9.35723 9.66831 10.5198 8.37331C10.5198 8.37331 15.8637 5.17898 16.0895 5.0874C17.6592 4.4539 19.6688 7.31573 19.6711 12.0034ZM17.6977 7.51581C17.0835 6.19515 15.9466 6.21731 15.3446 7.51698C15.0016 8.2584 14.7653 9.36323 14.6387 10.3152H14.9461C15.8719 10.3152 16.6221 11.066 16.6221 11.9923C16.6221 12.9186 15.8719 13.6694 14.9461 13.6694H14.6457C14.7723 14.6045 15.0051 15.7332 15.3492 16.5219C15.9256 17.8466 17.1354 17.8239 17.7018 16.5207C18.837 13.9091 18.7355 9.74881 17.6977 7.51581Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2486_33994"
        x1="0.671143"
        y1="0"
        x2="0.671143"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9900" />
        <stop offset="1" stopColor="#EE7700" />
      </linearGradient>
    </defs>
  </svg>
);
