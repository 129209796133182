import theme from "../../theme";
import { SideBarLinkConfig } from "./Sidebar";
import { useAppNavigate } from "../../hooks/navigation/useAppNavigate";

interface SidebarIconProps extends Omit<SideBarLinkConfig, "pathnames"> {
  selected: boolean;
}

export const SidebarLink = (props: SidebarIconProps) => {
  const navigate = useAppNavigate();
  return (
    <>
      {props.disabled ? (
        <div className={`${linkClasses} cursor-not-allowed`}>{props.icon}</div>
      ) : (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            console.log("SidebarLink: ", props.target);
            navigate(props.target);
          }}
          className={`${linkClasses} cursor-pointer`}
          style={{ backgroundColor: props.selected ? theme.palette.secondary.main : undefined }}
        >
          {props.icon}
        </a>
      )}
    </>
  );
};

const linkClasses = "h-16 flex items-center justify-center";
