import { ChangeEvent } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { FaultReportTableRow } from "./FaultReportsTableRow";
import {
  FaultReportCountPerPage,
  FaultReportFilter,
  FaultReportSortByValue,
  useFaultReportContext,
} from "../../context/FaultReportContext";
import { FaultStateVariant } from "../../hooks/navigation/routes";
import { GetFaultReportsResponse } from "../../dataQuery";

export function FaultReportsTable({
  variant,
  faultReports,
}: {
  variant: FaultStateVariant;
  faultReports?: GetFaultReportsResponse;
}) {
  const { currentFilter: filter, updateFilter } = useFaultReportContext();

  const handleClickHeadCell = (orderBy: FaultReportSortByValue) => () => {
    const newFilter: Partial<FaultReportFilter> = { orderBy };
    if (filter.orderBy === orderBy) {
      newFilter.orderDir = filter.orderDir === "ASC" ? "DESC" : "ASC";
    }
    updateFilter(newFilter);
  };

  function handleOnRowsPerPageChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const faultReportsLenght = faultReports?.results?.length || 0;
    const perPage = parseInt(e.target.value) as FaultReportCountPerPage;
    const coveredReportsCount = filter.page * filter.perPage + faultReportsLenght;
    let page = Math.ceil(coveredReportsCount / perPage) - 1;
    if (page < 0) {
      page = 0;
    }
    updateFilter({ perPage, page });
  }

  return (
    <Paper className="p-5">
      <Box className="p-0.5 relative">
        <Typography variant="h3" paragraph>
          {!faultReports ? <CircularProgress size={30} /> : getReportsCountLabel(faultReports.results.length)}
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={filter.orderBy === "technicalId"}
                  direction={filter.orderDir.toLowerCase() as "asc" | "desc"}
                  onClick={handleClickHeadCell("technicalId")}
                >
                  Leucht-/Schaltstelle
                </TableSortLabel>
              </TableCell>
              <TableCell>Ortsteil</TableCell>
              <TableCell>
                <TableSortLabel
                  active={filter.orderBy === "street"}
                  direction={filter.orderDir.toLowerCase() as "asc" | "desc"}
                  onClick={handleClickHeadCell("street")}
                >
                  Straße
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={filter.orderBy === "reportingTimestamp"}
                  direction={filter.orderDir.toLowerCase() as "asc" | "desc"}
                  onClick={handleClickHeadCell("reportingTimestamp")}
                >
                  Meldung
                </TableSortLabel>
              </TableCell>
              {variant === FaultStateVariant.INPROGRESSVIEW || variant === FaultStateVariant.INAPPROVALVIEW ? (
                <>
                  <TableCell>Melder</TableCell>
                  <TableCell>Bearbeitungsstatus</TableCell>
                </>
              ) : (
                <>
                  <TableCell>Melder</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={filter.orderBy === "completedAt"}
                      direction={filter.orderDir.toLowerCase() as "asc" | "desc"}
                      onClick={handleClickHeadCell("completedAt")}
                    >
                      Abgeschlossen
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Bearbeitungsstatus</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {faultReports?.results?.map((report) => (
              <FaultReportTableRow variant={variant} key={report.uuid} report={report} />
            ))}
          </TableBody>
          {!faultReports ? null : (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={faultReports?.total ? faultReports.total : 0}
                  page={filter.page}
                  rowsPerPage={filter.perPage}
                  rowsPerPageOptions={[10, 20, 50]}
                  onPageChange={(_, page) => updateFilter({ page })}
                  onRowsPerPageChange={handleOnRowsPerPageChange}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Box>
    </Paper>
  );
}

const getReportsCountLabel = (total: number) => {
  switch (total) {
    case 0:
      return `${total} Keine Meldungen`;
    case 1:
      return `${total} Meldung`;
    default:
      return `${total} Meldungen`;
  }
};
