import React, { useEffect, useState } from "react";
import {
  TextField,
  CircularProgress,
  Autocomplete,
  AutocompleteChangeReason,
  Typography,
  Alert,
  Collapse,
  IconButton,
  AlertColor,
  Button,
  ButtonProps,
  Box,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { LightPoint } from "../../models";
import { useGoogleAddressSearch } from "../../context";
import { LightPointUtils } from "../../utils/lightPointUtils";
import { LightPointSelectDialog } from "./LightPointSelectDialog/LightPointSelectDialog";
import { useEnersisGeoMap } from "../../context/EnersisGeoMapContext";
import {LongLatCoordinates} from "../GeoMapEnersis/GeoMap.types";

interface GeoSearchBarProps {
  title?: string;
  layout?: "row" | "column";
}

export const GeoSearchBar = (props: GeoSearchBarProps) => {
  const { searchAddress, getCoodinatesOfAddress } = useGoogleAddressSearch();

  const {
    selectedLightPoint,
    setCenter,
    searchLightPointsWithNumber,
    lightPoints,
    setSelectedLightPoint,
  } = useEnersisGeoMap();

  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [lightingNo, setLightingNo] = useState("");
  const [showNoLightPointsToast, setShowNoLightPointsToast] = useState(false);
  const [lightPointsToSelect, setLightPointsToSelect] = useState<LightPoint[]>([]);
  const [centerCoordinate, setCenterCoordinate] = useState<LongLatCoordinates>({Lat: 0, Long: 0});
  const [isLightPointLocationCorrect, setLightPointLocationCorrect] = useState<boolean>(true);

  useEffect(() => {
    if (!searchAddress) {
      return;
    }

    searchAddress(address).then((result) => {
      if (result) {
        setSearchOptions(result);
      }
    });
  }, [address, searchAddress]);

  const handleOnSelectLightPointViaDialog = (lightPoint: LightPoint, centerCoordinates: LongLatCoordinates) => {
    setLightPointsToSelect([]);
    selectLightpointInMap(lightPoint, centerCoordinates);
  };

  const selectLightpointInMap = (lightPoint: LightPoint, centerCoordinates: LongLatCoordinates) => {
    setMapCenter(lightPoint, centerCoordinates);
    const lightpointToSelect = lightPoints?.features.find((x) => x.properties.number === lightPoint.number);
    if (lightpointToSelect) {
      const { longitude, latitude, ...otherProperties } = lightpointToSelect.properties || {};
      setSelectedLightPoint({
        ...otherProperties,
        longitude: longitude || centerCoordinates.Long,
        latitude: latitude || centerCoordinates.Lat
      });
    }
  };

  const setMapCenter = (lightPoint: LightPoint, centerCoordinates: LongLatCoordinates) => {
    if (isLightPointLocationPresent(lightPoint)) {
      setCenter({ coordinates: { Lat: lightPoint.latitude, Long: lightPoint.longitude }, zoom: 18});
    } else {
      setLightPointLocationCorrect(false);
      setCenter({ coordinates: { Lat: centerCoordinates.Lat, Long: centerCoordinates.Long }, zoom: 14});
    }
  }

  const handleSearchClick = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLightPointLocationCorrect(true);

    setShowNoLightPointsToast(false);
    setIsLoading(true);

    if (lightingNo) {
      searchWithLightpointNumber(lightingNo);
    } else {
      await searchWitAddress();
    }
    setIsLoading(false);
  };

  const searchWithLightpointNumber = async (lightPointNumber: string) => {
    if (!searchLightPointsWithNumber) {
      return;
    }

    const result = await searchLightPointsWithNumber(lightPointNumber);
    const centerCoordinates = { Lat: result.centerLatitude, Long: result.centerLongitude };
    if (result.totalCount === 0) {
      setShowNoLightPointsToast(true);
      return;
    }
    if (result.totalCount === 1) {
      const lightpoint = result.data[0];
      selectLightpointInMap(lightpoint, centerCoordinates);
      return;
    }

    setCenterCoordinate(centerCoordinates);
    setLightPointsToSelect(result.data);
  };

  const isLightPointLocationPresent = (lightPoint: LightPoint) => {
    return lightPoint.longitude && lightPoint.latitude;
  }

  const searchWitAddress = async () => {
    if (!getCoodinatesOfAddress) {
      return;
    }
    const geocoderResult = await getCoodinatesOfAddress(address);

    if (geocoderResult) {
      setCenter({ coordinates: { Lat: geocoderResult.lat, Long: geocoderResult.lng }, zoom: 18});
    }
  };

  useEffect(() => {
    let value = {
      lightingNr: "",
      address: "",
    };
    if (selectedLightPoint && !selectedLightPoint.incident) {
      value.lightingNr = selectedLightPoint.number;
      value.address = LightPointUtils.getLightPointAddress(selectedLightPoint);
    }
    setLightingNo(selectedLightPoint?.number || "");
    setAddress(value.address);
  }, [selectedLightPoint]);

  function onSelectAdress(
    _: React.SyntheticEvent<Element, Event>,
    selectedAddress: string | null,
    reason: AutocompleteChangeReason
  ) {
    if (reason === "selectOption") {
      setAddress(selectedAddress || "");
    }
  }

  const renderSearchButtonContent = () => {
    if (isLoading) {
      return <CircularProgress color="secondary" style={{ width: 24, height: 24 }} />;
    } else if (!address && !lightingNo) {
      return "Erneut laden";
    } else {
      return "Suche starten";
    }
  };

  return (
    <>
      <form onSubmit={(e) => handleSearchClick(e)} style={{ width: "100%", maxWidth: "1000px" }}>
        {props.title && (
          <Typography variant="h5" color="black" sx={{ marginY: "20px" }}>
            {props.title}
          </Typography>
        )}
        {selectedLightPoint?.incident && (
          <Alert severity="info" sx={{ marginY: "20px" }}>
            Störung anlegen nicht möglich!
            <br />
            Es existiert bereits eine Störung.
          </Alert>
        )}
        {!isLightPointLocationCorrect && (
            <Alert severity="error" sx={{ marginY: "20px" }}>
              Leider fehlen die entsprechenden Koordinaten in der Datenbank zur Anzeige der Leuchtstelle.
              <br /><br />
              Bitte geben Sie uns eine Rückmeldung dazu mit der entsprechenden Leuchtstellennummer über das Kontaktformular.
              <br /><br />
              Wir werden die Daten entsprechend ergänzen. Vielen Dank.
            </Alert>
        )}
        <Box
          display="flex"
          flexDirection={props.layout ?? "column"}
          justifyContent="space-between"
          gap="20px"
          marginBottom={"20px"}
        >
          <Autocomplete
            freeSolo //TODO: this is dangerous
            renderInput={(params) => <TextField {...params} label="Adresse" placeholder="Straßenname ggf. Hausnr." />}
            inputValue={address}
            options={searchOptions || []}
            onInputChange={(_, v) => setAddress(v)}
            onChange={onSelectAdress}
            fullWidth
            id={"searchbar-address-autocomplete"}
            sx={{ flex: 4 }}
          />
          <TextField
            fullWidth
            label="Leuchtstelle"
            value={lightingNo}
            onChange={(e) => setLightingNo(e.target.value)}
            placeholder="Leuchtstelle suchen"
            sx={{ flex: 2 }}
          />
          <CreateFaultReportButton sx={{ flex: 1 }} type="submit">
            {renderSearchButtonContent()}
          </CreateFaultReportButton>
        </Box>
        <ClosableToast
          onClose={() => setShowNoLightPointsToast(false)}
          open={showNoLightPointsToast}
          text="Keine Leuchtstellen gefunden"
          variant="error"
        />
      </form>

      <LightPointSelectDialog
        onClose={() => setLightPointsToSelect([])}
        lightPoints={lightPointsToSelect}
        centerCoordinate={centerCoordinate}
        onSelect={handleOnSelectLightPointViaDialog}
      />
    </>
  );
};

interface ClosableToastProps {
  open: boolean;
  variant: AlertColor;
  onClose: () => void;
  text: string;
}

function ClosableToast(props: ClosableToastProps) {
  return (
    <Collapse in={props.open}>
      <Alert
        severity={props.variant}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={props.onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {props.text}
      </Alert>
    </Collapse>
  );
}

export const CreateFaultReportButton = (props: ButtonProps) => (
  <Button variant="contained" {...props} size="large" sx={{ ...props.sx, width: "clamp(8rem, 100%, 10rem)" }} />
);
