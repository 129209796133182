import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {GeoMapEnersis} from "../GeoMapEnersis/GeoMapEnersis";
import React from "react";

interface FaultLocationDialogProps {
    open: boolean;
    lightingNo: string | undefined;
    mapCenter: [number, number];
    onDialogClose: () => void;
}

export const FaultLocationDialog = (props: FaultLocationDialogProps) => {
    return (
        <Dialog
            open={props.open}
            maxWidth={"lg"}
            fullWidth={true}
        >
            <DialogTitle>
                <Typography>Incident location:</Typography>
            </DialogTitle>
            <DialogContent className="flex flex-col">
                <GeoMapEnersis
                    mapZoom={15}
                    mapName="TestMap"
                    promoteId="number"
                    loadOnEntry={false}
                    lightingNo={props.lightingNo}
                    center={props.mapCenter}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="contained" onClick={props.onDialogClose}>
                    Zurück
                </Button>
            </DialogActions>
        </Dialog>

    );
};
