import { useQuery } from "react-query";
import { GenericErrorType } from "../../context/ErrorContext/LionError";
import { MaintenanceReport } from "../../models/MaintenanceReport";
import { useApiClient } from "../apiClient/apiClient";

const refetchInterval = 1000 * 60 * 15; //every 15mins

export function useMaintenanceReports() {
  const apiClient = useApiClient();

  return useQuery(
    "maintenances",
    () => apiClient.get<MaintenanceReport[]>("maintenances/lion", GenericErrorType.UNSPECIFIED),
    { cacheTime: 0, refetchInterval, retry: 2 }
  );
}
