import { Box, CircularProgress } from "@mui/material";

export function Spinner() {
  return (
    <Box className="flex justify-center">
      <CircularProgress />
    </Box>
  );
}

export const FullscreenSpinner = () => (
  <div className="flex items-center justify-center w-full h-full">
    <CircularProgress />
  </div>
);