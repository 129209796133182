import React from "react";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";

interface Props {
  label: string;
  children: React.ReactNode;
  className: string;
}

export const InfoCard = ({ label, children, className }: Props) => {
  return (
    <Paper className={className + " p-5"} elevation={3}>
      <Typography color="primary" variant="h4" className="mb-5">
        {label}
      </Typography>
      <div className="gap-5 break-words flex flex-col">{children}</div>
    </Paper>
  );
};
