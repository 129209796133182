export const ThunderIcon = (color: any = "#000099", size: number | string = 30) => {
  return (
    <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill={color} />
      <path
        d="M16.5861 22.4228C12.0011 21.4251 14.3377 18.4938 17.2553 13.8691C21.4595 6.13443 22.8571 4.37777 21.6057 11.4633C20.5766 16.2839 21.0867 17.5644 23.6135 17.6113C27.9683 17.7744 25.5892 21.1985 22.6096 26.4323C17.8256 34.7017 16.8464 35.1789 18.5939 27.7688C19.1922 24.3808 19.0581 22.9197 16.5861 22.4228Z"
        fill="white"
      />
    </svg>
  );
};
