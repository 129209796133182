import { NewFaultReport, FaultReport, ApprovalState } from "../../models";
import { useAppContext } from "../../context";
import { useApiClient } from "../apiClient/apiClient";
import { FaultReportErrorType, GenericErrorType } from "../../context/ErrorContext/LionError";
import { useMemo } from "react";
import { GetFaultReportsResponse } from "./faultReportsApi.types";
import { FaultReportFilter } from "../../context/FaultReportContext";
import { getParamsURLEncoded } from "../apiParamFactory";
import { IncidentStatus } from "../../models/IncidentStatus";
import _ from "lodash";

export function useFaultReportsApi() {
  const { customerScope } = useAppContext();
  const apiClient = useApiClient();

  return useMemo(
    () => ({
      getInProgressFaultReports(filter: FaultReportFilter): Promise<GetFaultReportsResponse> {
        if (filter.incidentStatus?.length > 0) {
          return apiClient.get<GetFaultReportsResponse>(
            `/v2/faultreports/?${getParamsURLEncoded(filter, customerScope)}`,
            FaultReportErrorType.FAULTREPORT_GET
          );
        }
        return apiClient.get<GetFaultReportsResponse>(
          `/v2/faultreports/inprogress?${getParamsURLEncoded(filter, customerScope)}`,
          FaultReportErrorType.FAULTREPORT_GET
        );
      },
      getCompletedFaultReports(filter: FaultReportFilter): Promise<GetFaultReportsResponse> {
        if (filter.incidentStatus?.length > 0) {
          return apiClient.get<GetFaultReportsResponse>(
            `/v2/faultreports/?${getParamsURLEncoded(filter, customerScope)}`,
            FaultReportErrorType.FAULTREPORT_GET
          );
        }
        return apiClient.get<GetFaultReportsResponse>(
          `/v2/faultreports/completed?${getParamsURLEncoded(filter, customerScope)}`,
          FaultReportErrorType.FAULTREPORT_GET
        );
      },
      getFaultReportsToApprove(filter: FaultReportFilter): Promise<GetFaultReportsResponse> {
        const filterClone = _.cloneDeep(filter);
        filterClone.incidentStatus = [IncidentStatus.ApprovalRequiredByCommune];
        return apiClient.get<GetFaultReportsResponse>(
          `/v2/faultreports?${getParamsURLEncoded(filterClone, customerScope)}`,
          FaultReportErrorType.FAULTREPORT_GET
        );
      },
      getAllFaultReports(filter: FaultReportFilter): Promise<GetFaultReportsResponse> {
        return apiClient.get<GetFaultReportsResponse>(
          `/v2/faultreports/?${getParamsURLEncoded(filter, customerScope)}`,
          FaultReportErrorType.FAULTREPORT_GET
        );
      },
      post(fault: NewFaultReport): Promise<FaultReport> {
        return apiClient.post<FaultReport, NewFaultReport>(
          `v2/faultreports?customerScope=${customerScope}`,
          fault,
          FaultReportErrorType.FAULTREPORT_CREATE
        );
      },
      deleteFault(id: string): Promise<void> {
        return apiClient.remove(`faultreports/${id}?customerScope=${customerScope}`, GenericErrorType.UNCRITICAL);
      },
      setApprovalState(id: string, approvalStateTO: ApprovalState): Promise<void> {
        return apiClient.put(
          `/v2/faultreports/${id}/approvalState?customerScope=${customerScope}`,
          approvalStateTO,
          GenericErrorType.UNCRITICAL
        );
      },
    }),
    [apiClient, customerScope]
  );
}
