import { useEffect, useRef, useCallback, useMemo } from "react";
import { GpMapViewer } from "@enersis/gp-components/react/gp-map2-viewer";
import { GpMapControl } from "@enersis/gp-components/react/gp-map2-control";
import type { GpMapViewerElement } from "@enersis/gp-components/gp-map2-viewer";
import {
  GeoJsonLightpoint,
  GeoJsonLightpointFeatureCollection,
  GeoMapProps,
  Incident,
  LightpointProperties,
  MapBoxMouseEnterEvent as MapBoxMouseEvent,
} from "./GeoMap.types";
import mapboxgl, { LngLatBounds } from "mapbox-gl";
import { renderToStaticMarkup } from "react-dom/server";
import { subscribeToMapEvent, unsubscribeFromMapEvent } from "@enersis/gp-components/map";
import ReactDOM from "react-dom";
import { useEnersisGeoMap } from "../../context/EnersisGeoMapContext";
import { format } from "date-fns";
import { useAppNavigate } from "../../hooks/navigation/useAppNavigate";
import {Backdrop, CircularProgress, Paper, Typography} from "@mui/material";
import { withStyles } from "@mui/styles";
import {AppRoute} from "../../hooks/navigation/routes";
import { colors } from "../../theme";
import {
  InProgressStatus,
  IncidentStatus,
  determineStateIconColor,
  getStatusInOverviewLabel,
} from "../../models/IncidentStatus";

const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
  },
})(Backdrop);

const InfoWindow = ({
  lightpoint,
  onIncidentClick,
  onNewReportClick,
  showFaultReport = true,
}: {
  lightpoint: LightpointProperties;
  onIncidentClick: (lightpoint: LightpointProperties) => void;
  onNewReportClick: () => void;
  showFaultReport?: boolean;
}) => (
  <div style={{ padding: 10 }}>
    <div style={{ fontWeight: "bold" }}>{lightpoint.number}</div>
    <div>
      {lightpoint.street} {lightpoint.houseNumber}
    </div>
    <div>
      {lightpoint.city} {lightpoint.city}
    </div>
    {!lightpoint.incident ? (
      <button
        style={{
          color: colors.common.white,
          backgroundColor: colors.secondary.main,
          borderRadius: "4px",
          border: 0,
          fontSize: "0.7rem",
          fontWeight: 400,
          minWidth: "64px",
          justifyContent: "center",
          position: "relative",
          boxSizing: "border-box",
          display: "inline-flex",
          padding: "6px 16px",
          margin: 0,
          outline: 0,
          alignItems: "center",
          cursor: "pointer",
          lineHeight: 1.75,
          fontFamily:
            "'EnBWDINPro', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        }}
        onClick={() => onNewReportClick()}
      >
        <svg
          viewBox="0 0 24 24"
          style={{
            fill: "currentcolor",
            flexShrink: 0,
            fontSize: "1.2rem",
            width: "1em",
            height: "1em",
            fontWeight: 400,
            display: "inline-block",
            fontFamily:
              "'EnBWDINPro', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
            transitionProperty: "fill",
            transitionDuration: "200ms",
            transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
            transitionDelay: "0ms",
          }}
        >
          <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
        </svg>
        NEUE STÖRUNGSMELDUNG
      </button>
    ) : (
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <div>
          <strong style={{ display: "inline-block", width: "40%" }}>Status:</strong>
          <span>{getStatusInOverviewLabel(lightpoint.incidentStatus)}</span>
        </div>
        <div>
          <strong style={{ display: "inline-block", width: "40%" }}>Gemeldet:</strong>
          <span>
            {lightpoint.incident
              ? format(
                  new Date(lightpoint.incident.reportingTimestamp),
                  "dd.MM.yyyy hh:mm" // incident is not parsed into object. have to parse manuelly
                )
              : "-"}
          </span>
        </div>
        {showFaultReport ? (
          <button style={{ alignSelf: "flex-start", padding: 10 }} onClick={() => onIncidentClick(lightpoint)}>
            Störungsmeldung anzeigen
          </button>
        ) : (
          <span>Diese Leuchtstelle kann nicht ausgewählt werden.</span>
        )}
      </div>
    )}
  </div>
);

/**
 *
 * @example
 * <GeoMap
 *   features={[{
 *     type: 'Feature',
 *     properties: {
 *       customerScope: "ZV_Laiern",
 *       number: "LAITA-00000-04021",
 *       incidentUuid: "34c61ade-186a-4337-940a-873d61abc89b",
 *       incidentState: IN_PROGRESS,
 *     },
 *     geometry: {
 *       type: 'Point',
 *       coordinates: [9.14000, 48.93919]
 *     },
 *   }]}
 *   thematics={[{
 *     id: "incidentState",
 *     label: "Meldung",
 *     styleRules: {
 *       color: [
 *         {
 *           type: "category",
 *           input: ["get", "incidentState"],
 *           steps: [
 *             { value: "APPROVAL_PENDING", output: "#FF0000" },
 *             { value: "IN_PROGRESS", output: "#FFB900" },
 *           ],
 *         },
 *         {
 *           type: "default",
 *           output: "#000FFF"
 *         },
 *       ]
 *     }
 *   }]}
 *   center={[9.14000, 48.93919]}
 *   zoom={18}
 *   onFeatureClick={(featureProperties) => console.log(featureProperties.number === "LAITA-00000-04021")}
 * />
 */
export function GeoMapEnersis({ ...props }: GeoMapProps) {
  const navigate = useAppNavigate();
  const {
    apiKey,
    fetchAllLightPointsAsGeoJson,
    setSelectedLightPoint,
    loadLightpoints,
    center,
    selectedLightPoint,
    mapIsLoading,
    lightPoints,
  } = useEnersisGeoMap();

  const mapContainer = useRef<GpMapViewerElement>(null);
  const defaultZoomLevel = 6;

  const handleClickNewReport = useCallback(() => {
    navigate(AppRoute.CREATE_REPORT);
  }, [navigate]);

  const navigateToIncident = useCallback((lightpoint: LightpointProperties) => {
    navigate({ id: lightpoint.incident.incidentUuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLightPointsAndCenterIt = useCallback((lightPoints: GeoJsonLightpointFeatureCollection) => {
    const gpMapViewer = mapContainer.current;
    if (!gpMapViewer) return;

    if (!lightPoints || !lightPoints.features) {
      return;
    }
    const map = gpMapViewer.map;

    gpMapViewer.gpSources.lightPoints.updateSource({
      data: {
        type: "FeatureCollection",
        features: lightPoints.features as any,
      },
    });

    props.onDataRefresh?.(lightPoints);

    console.log("Center map");
    map.fitBounds(getBounds(lightPoints), { padding: 100 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const gpMapViewer = mapContainer.current;
    if (!gpMapViewer || !gpMapViewer.gpSources.lightPoints || !lightPoints) return;

    //remove popup
    if (onClickPopup) onClickPopup.current.remove();

    gpMapViewer.gpSources.lightPoints.updateSource({
      data: {
        type: "FeatureCollection",
        features: lightPoints.features as any,
      },
    });
  }, [lightPoints]);

  useEffect(() => {
    const map = mapContainer.current?.map;
    if (center?.coordinates?.Lat && center?.coordinates?.Long) {
      map?.flyTo({ center: { lat: center?.coordinates?.Lat, lon: center?.coordinates?.Long }, zoom: center?.zoom || 18 });
    }
  }, [center]);

  useEffect(() => {
    if (!fetchAllLightPointsAsGeoJson || !props.loadOnEntry) return;

    fetchAllLightPointsAsGeoJson()
      .then((featureCollection) => {
        setLightPointsAndCenterIt(featureCollection);
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const reference = onClickPopup.current;
    //remove popup
    if (onClickPopup) {
      reference.remove();
    }

    return () => {
      reference.remove();
      setSelectedLightPoint(undefined)
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const properties = selectedLightPoint as LightpointProperties;
    const map = mapContainer.current?.map;
    if (!popup.current || !map || !onClickPopup || !properties) return;

    const content = document.createElement("div");

    ReactDOM.render(
      <InfoWindow
        lightpoint={properties as LightpointProperties}
        onIncidentClick={navigateToIncident}
        onNewReportClick={handleClickNewReport}
      ></InfoWindow>,
      content
    );
    onClickPopup.current
      .setLngLat([properties.longitude, properties.latitude] as [number, number])
      .setDOMContent(content)
      .addTo(map);
  }, [navigateToIncident, selectedLightPoint, handleClickNewReport]);

  const popup = useRef<mapboxgl.Popup>(
    new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    })
  );

  useEffect(() => {
    if (!mapIsLoading && lightPoints) {
      const data = lightPoints.features.find(
          (feature) => feature.properties.number === props.lightingNo
      ) as GeoJsonLightpoint;

      const properties = data?.properties;
      if (properties) {
        setSelectedLightPoint(properties);
        props.onLightpointClick?.(properties);
      }
    }
  }, [lightPoints, mapIsLoading]);

  const onClickPopup = useRef<mapboxgl.Popup>(
    new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: false,
    })
  );

  const handleOnHover = useCallback(
    (e: MapBoxMouseEvent) => {
      if (!e.features) {
        return;
      }

      const feature = (e.features[0] as any) as GeoJsonLightpoint;
      const map = mapContainer.current?.map;
      if (!feature || !popup.current || !map) return;

      let lightpoint = feature.properties;
      if (lightpoint.incident) {
        lightpoint.incident = JSON.parse(lightpoint.incident as any) as Incident;
      }

      const popupContent = renderToStaticMarkup(
        <InfoWindow
          lightpoint={lightpoint}
          onIncidentClick={navigateToIncident}
          onNewReportClick={handleClickNewReport}
        />
      );
      popup.current
        .setLngLat([lightpoint.longitude, lightpoint.latitude] as [number, number])
        .setHTML(popupContent)
        .on("close", () => {})
        .addTo(map);
    },
    [navigateToIncident, handleClickNewReport]
  );

  const handleOnHoverOut = useCallback(() => {
    const map = mapContainer.current?.map;
    if (!popup.current || !map) return;
    popup.current.remove();
  }, []);

  const handleOnClick = useCallback((e: MapBoxMouseEvent) => {
    const data = e.features?.[0];
    const map = mapContainer.current?.map;
    if (!data || !map) {
      onClickPopup.current.remove();
      return;
    }
    let properties = data.properties as LightpointProperties;
    if (properties.incident) {
      properties.incident = JSON.parse(properties.incident as any) as Incident;
    }

    setSelectedLightPoint(properties);
    props.onLightpointClick?.(properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnLoad = useCallback(() => {
    loadLightpoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addEventListeners = useCallback(() => {
    const map = mapContainer.current?.map;
    if (!map) return;

    map.on("mouseenter", "lightPoints", handleOnHover);
    map.on("mouseleave", "lightPoints", handleOnHoverOut);
    map.on("click", "lightPoints", handleOnClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeEventListeners = useCallback(() => {
    const map = mapContainer.current?.map;
    if (!map) return;
    map.off("mouseleave", "lightPoints", handleOnHoverOut);
    map.off("click", "lightPoints", handleOnClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let hasEventListeners = false;
    const token = subscribeToMapEvent(props.mapName, "stateChanged", ({ state }) => {
      if (state === "mapReady" && !hasEventListeners) {
        addEventListeners();
        hasEventListeners = true;
        handleOnLoad();
      }
    });
    return () => {
      unsubscribeFromMapEvent(token);
      removeEventListeners();
    };
  }, [addEventListeners, removeEventListeners, handleOnLoad, props.mapName]);

  return useMemo(
    () => (
      <Paper className="flex relative flex-col flex-1">
        <GpMapViewer
          ref={mapContainer}
          options={{
            accessToken: apiKey,
          }}
          mapConfig={{
            viewer: {
              // fonts from node_modules/@enersis/gp-components/assets/map importiert in public verzeichnis des projekts
              glyphs: "/map/fonts/{fontstack}/{range}.pbf",
              zoom: props.mapZoom || defaultZoomLevel,
              center: props.center,
              layers: [
                { type: "template", id: "streetV1/satellite" },
                { type: "template", id: "streetV1" },
                {
                  type: "circle",
                  id: "lightPoints",
                  source: {
                    type: "geojson",
                    promoteId: props.promoteId,
                  },
                  selectable: "single",
                  hoverable: true,
                  thematics: [
                    {
                      id: "incidentState",
                      label: "Meldung",
                      styleRules: {
                        color: [
                          {
                            type: "category",
                            input: ["get", "incidentStatus"],
                            steps: [
                              {
                                value: IncidentStatus.ApprovalRequiredByCommune,
                                output: determineStateIconColor(IncidentStatus.ApprovalRequiredByCommune),
                              },
                              ...InProgressStatus.map((status) => {
                                return { value: status, output: determineStateIconColor(status) };
                              }),
                            ],
                          },
                          { type: "default", output: "#000FFF" },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
            extensions: {
              mapControl: {
                disableButtonLabel: true,
                buttonListOrder: ["basemap", "zoom"],
                buttonListOrientation: "vertical",
                basemap: {
                  basemaps: ["street", "satellite"],
                  satelliteBW: undefined,
                  terrain: undefined,
                  activeBasemap: "street",
                },
              },
            },
          }}
          mapName={props.mapName}
        >
          <GpMapControl mapName={props.mapName}></GpMapControl>
          <LimitedBackdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={mapIsLoading}>
            <CircularProgress color="inherit" />
            <Typography>Daten werden geladen...</Typography>
          </LimitedBackdrop>
        </GpMapViewer>
      </Paper>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapIsLoading]
  );
}

function getBounds(featureCollection: GeoJsonLightpointFeatureCollection): LngLatBounds {
  const { features } = featureCollection;
  const bounds = new LngLatBounds();
  features.forEach((feature) => {
    const [lng, lat] = [feature.properties.longitude, feature.properties.latitude];
    if (lng && lat) {
      bounds.extend([lng, lat]);
    }
  });
  return bounds;
}
