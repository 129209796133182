import { FaultReportFilter } from "../context/FaultReportContext";

export namespace FaultUtils {
  export function getPageForNewFilter(
    currentFilter: FaultReportFilter,
    newFilter: Partial<FaultReportFilter>,
    defaultPage: number
  ): number {
    const filterPropChanged = (k: keyof FaultReportFilter) => propertyChaned(newFilter, currentFilter, k);
    if (filterPropChanged("page")) {
      return newFilter.page as number;
    } else if (filterPropChanged("incidentStatus")) {
      return defaultPage;
    }
    return defaultPage;
  }
}


const propertyChaned = <T>(a: Partial<T>, b: Partial<T>, k: keyof T) => a[k] !== undefined && a[k] !== b[k];