import { useKeycloak } from "@react-keycloak/web";
import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
  useMemo,
  useState,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import { FaultReport } from "../models";
import { useCustomerScope } from "../hooks/customerScope";
import { checkIfReaderOnly, hostnames } from "../dataQuery";

const AGS_SEARCH_PARAM_NAME = "ags";
const ZV_SEARCH_PARAM_NAME = "zv";
const HEADLESS_SEARCH_PARAM_NAME = "headless";

interface AppContextValue {
  isHeadless: null | boolean;
  customerScope?: string;
  isReaderOnly?: boolean;
  displayingReport?: FaultReport;
  setDisplayingReport: (v?: FaultReport) => void;
  environment: Environment;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isOnMaintenanceRef: MutableRefObject<boolean>;
  maintenanceFetched: boolean;
  setMaintenanceFetched: Dispatch<SetStateAction<boolean>>;
}

export const AppContext = createContext<AppContextValue>({
  isHeadless: false,
  setDisplayingReport: () => console.warn("setDisplayingReport not implemented!"),
  environment: "dev",
  isLoading: false,
  setIsLoading: (_) => {},
  isOnMaintenanceRef: { current: false },
  maintenanceFetched: false,
  setMaintenanceFetched: () => {},
});

export function AppContextProvider({ children }: { children: React.ReactNode }) {
  const [displayingReport, setDisplayingReport] = useState<FaultReport | undefined>();
  const isOnMaintenanceRef = useRef<boolean>(false);
  const [maintenanceFetched, setMaintenanceFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const { keycloak } = useKeycloak();
  const searchParams = new URLSearchParams(search);

  const [isHeadless] = useState<boolean>(searchParams.get(HEADLESS_SEARCH_PARAM_NAME) === "true");
  const [customerScopeSearchParam] = useState<string | null>(getCustomerScopeFromRequestParams(searchParams));

  const customerScope = useCustomerScope({ keycloak, customerScopeSearchParam });

  const environment = useMemo(() => getEnvironment(), []);

  const isReaderOnly = checkIfReaderOnly(keycloak);

  const value: AppContextValue = {
    environment,
    isHeadless,
    customerScope,
    isReaderOnly,
    displayingReport,
    setDisplayingReport,
    isLoading,
    setIsLoading,
    isOnMaintenanceRef,
    maintenanceFetched,
    setMaintenanceFetched,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export const useAppContext = () => useContext(AppContext);

function getCustomerScopeFromRequestParams(searchParams: URLSearchParams): string | null {
  if (!!searchParams.get(AGS_SEARCH_PARAM_NAME)) {
    // e.g. ags=08317114 (Rust) -> AGS_08317114
    return `AGS_${searchParams.get(AGS_SEARCH_PARAM_NAME)}`;
  }
  if (!!searchParams.get(ZV_SEARCH_PARAM_NAME)) {
    // e.g. zv=ZV_Laiern -> ZV_Laiern
    return searchParams.get(ZV_SEARCH_PARAM_NAME);
  }
  return null;
}

export interface MultiEnvValue<T> {
  dev: T;
  stage: T;
  prod: T;
}

export type Environment = keyof MultiEnvValue<string>;

export function getEnvironment(): Environment {
  const hostname = window.location.hostname;
  if (hostnames.stage.includes(hostname)) {
    return "stage";
  } else if (hostnames.prod.includes(hostname)) {
    return "prod";
  }
  return "dev";
}