import { Box, Dialog, DialogContent, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppContext } from "../../context";
import { useErrorContext } from "../../context/ErrorContext";
import { useMaintenanceReports } from "../../dataQuery/maintenanceApi/useMaintenanceReports";
import { MaintenanceReport } from "../../models/MaintenanceReport";
import { Utils } from "../../utils/utils";

export function MaintenanceDialog() {
  const { isOnMaintenanceRef, maintenanceFetched, setMaintenanceFetched } = useAppContext();
  const { setError } = useErrorContext();

  const [maintenance, setMaintenance] = useState<MaintenanceReport | undefined>();

  const { data: reports, isError } = useMaintenanceReports();

  useEffect(() => {
    if (isError) {
      if (!maintenanceFetched) {
        setMaintenanceFetched(true);
      }
    }
  }, [isError, maintenanceFetched, setMaintenanceFetched]);

  useEffect(() => {
    if (!reports) return;
    const maintenance = Utils.getCurrentMaintenance(reports, new Date());
    setMaintenance(maintenance);
    isOnMaintenanceRef.current = maintenance !== undefined;
    if (!maintenanceFetched) {
      setMaintenanceFetched(true);
    }
    if (maintenance !== undefined) {
      setError(undefined);
    }
  }, [reports, isOnMaintenanceRef, setError, maintenanceFetched, setMaintenanceFetched]);

  if (!maintenance) return null;

  const formatedStartDateTime = Utils.formatToLocalDate(new Date(maintenance.startDateTime));
  const formatedEndDateTime = Utils.formatToLocalDate(new Date(maintenance.endDateTime));

  return (
    <Dialog aria-label="maintenance-dialog" open={!!maintenance}>
      <Paper className="flex flex-col">
        <Box className="flex px-5 pt-5 items-center justify-between">
          <Box>
            <Typography className="text-xl font-semibold">Geplante Wartungsarbeiten</Typography>
            <Typography className="text-lg font-semibold">
              von {formatedStartDateTime} Uhr bis {formatedEndDateTime} Uhr
            </Typography>
          </Box>
          <img alt="" src="wartung.png" width={100} style={{ float: "left" }} />
        </Box>
        <DialogContent>
          <Typography>Sehr geehrte Damen und Herren,</Typography>
          <br />
          <Typography>
            aufgrund von technischen Wartungsarbeiten ist das Modul Straßenbeleuchtung aktuell nicht verfügbar. Wir
            bitten um Ihr Verständnis. Nach Abschluss der geplanten Wartungsarbeiten können Sie das Modul in gewohnter
            Weise verwenden.
          </Typography>
          <br />
          <br />
          <Typography>Ihr #LightOn-Team der Netze BW GmbH</Typography>
        </DialogContent>
      </Paper>
    </Dialog>
  );
}
