import { TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent, memo, useEffect, useState } from "react";
import { useDebounce } from "../../hooks/useDebounce";

interface AsyncTextFieldProps {
  onAsyncChange: (v: string) => void;
  value?: string;
  debounceNumber?: number;
}

type Props = AsyncTextFieldProps & Omit<TextFieldProps, "onChange">;

export const AsyncTextField = memo(({ debounceNumber = 300, onAsyncChange, value, ...props }: Props) => {
  const debounce = useDebounce();
  const [hasChangedValue, setHasChangedValue] = useState(false);
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    if (!hasChangedValue) return;
    debounce(onAsyncChange, debounceNumber)(inputValue);
  }, [inputValue]);

  function handleOnChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (!hasChangedValue) {
      setHasChangedValue(true);
    }
    setInputValue(e.target.value);
  }

  return <TextField value={inputValue} {...props} onChange={handleOnChange} />;
});
