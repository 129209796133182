import { PropsWithChildren } from "react";
import { FullscreenSpinner } from "./Spinner";

type LoadingProviderProps = PropsWithChildren<{
  isLoading: boolean;
}>;

export const LoadingProvider = (props: LoadingProviderProps) => (
  <>{props.isLoading ? <FullscreenSpinner /> : props.children}</>
);
