import { FaultReport } from "../../models";
import { useQuery } from "react-query";
import { useAppContext } from "../../context";
import { useApiClient } from "../apiClient/apiClient";
import { GenericErrorType } from "../../context/ErrorContext/LionError";

export function useFaultReport(id?: string) {
  const { customerScope, isOnMaintenanceRef } = useAppContext();
  const apiClient = useApiClient();

  return useQuery(
    ["faultreport", id],
    () =>
      apiClient.get<FaultReport>(`v2/faultreports/${id}?customerScope=${customerScope}`, GenericErrorType.UNCRITICAL),
    {
      enabled: !!customerScope && !!id && !isOnMaintenanceRef.current,
    }
  );
}
