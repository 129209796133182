export enum IssueType {
  CABLE_INCIDENT = "CABLE_INCIDENT",
  DEFAULT_INCIDENT = "DEFAULT_INCIDENT",
  ACCIDENT = "ACCIDENT",
  VANDALISM = "VANDALISM",
}

export const getIssueTypeLabel = (issueType: IssueType): string => {
  switch (issueType) {
    case IssueType.ACCIDENT:
      return "Unfall";
    case IssueType.CABLE_INCIDENT:
      return "Kabelfehler";
    case IssueType.DEFAULT_INCIDENT:
      return "normale Störung";
    case IssueType.VANDALISM:
      return "Vandalismus";
    default:
      return "";
  }
};
