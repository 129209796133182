import { AppBar, Box } from "@mui/material";
import React, { useContext } from "react";
import { AppContext } from "../context";
import theme from "../theme";

export function Header() {
  const { isHeadless } = useContext(AppContext);

  if (isHeadless) return null;

  return (
    <AppBar style={{ backgroundColor: theme.palette.grey[200] }}>
      <Box style={{ padding: "24px 0 24px 125px" }}>
        <img src={process.env.PUBLIC_URL + "/marke_logo_netz.png"} alt="Marke Logo Netze BW"></img>
      </Box>
    </AppBar>
  );
}
