export enum GenericErrorType {
  CRITICAL = "CRITICAL",
  UNCRITICAL = "UNCRITICAL",
  MAINTENANCE = "MAINTENANCE",
  UNSPECIFIED = "UNSPECIFIED",
}

export enum FaultReportErrorType {
  FAULTREPORT_CREATE = "FAULTREPORT_CREATE",
  FAULTREPORT_GET = "FAULTREPORT_GET",
}

export enum LightPointErrorType {
  REFETCH_LIGHTPOINT = "REFETCH_LIGHTPOINT",
  FETCH_LIGHTPOINTS = "FETCH_LIGHTPOINTS",
}

export enum ImageErrorType {
  IMAGE_UPLOAD = "IMAGE_UPLOAD",
}

export type LionErrorType = GenericErrorType | FaultReportErrorType | LightPointErrorType | ImageErrorType;

export interface ErrorContent {
  title: string;
  message: string;
}

export class LionError {
  public readonly errorType: LionErrorType;
  public readonly content?: ErrorContent;

  constructor(errorType: LionErrorType, content?: ErrorContent) {
    this.errorType = errorType;
    this.content = content;
  }
}

export function isLionError(err: any): err is LionError {
  return (err as LionError)?.errorType !== undefined;
}
