import { useNavigate, useSearchParams } from "react-router-dom";
import { Utils } from "../../utils/utils";
import { AppRoute } from "./routes";

interface ReportDetails {
  id: string;
}
const reportDetailsRoute = (id: string, agsNumber?: string) => `/meldungen/${id}?ags=${agsNumber}`;
const isReportDetails = (a: any): a is ReportDetails => Utils.hasText((a as ReportDetails).id);

export function useAppNavigate() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  function handleNavigate(route: AppRoute | ReportDetails) {
    const ags = searchParams.get("ags");
    const agsString = ags ? ags : "";
    navigate(isReportDetails(route) ? reportDetailsRoute(route.id, agsString) : `${route}?ags=${agsString}`);
  }

  return handleNavigate;
}
