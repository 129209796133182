import { KeycloakInstance } from "keycloak-js";
import { getGroups, hasGroupsInScope } from "../dataQuery";

const PREFIX_AGS = "PAM-AGS";
const PREFIX_ZWECKVERBAND = "PAM-ZV";

export function useCustomerScope({
  keycloak,
  customerScopeSearchParam,
}: {
  keycloak: KeycloakInstance;
  customerScopeSearchParam: string | null;
}) {
  const { authenticated, tokenParsed } = keycloak;

  let customerScope: string | undefined | null;
  if (authenticated) {
    if (hasGroupsInScope(tokenParsed)) {
      const groups = getGroups(tokenParsed); // Contains Admin roles

      if (groups.includes("FGM-ADMIN_USER") || groups.includes("FGM-ADMIN_READER")) {
        // ADMIN_USER OR ADMIN_READER have access to every AGS - given by searchParam
        if (customerScopeSearchParam) {
          customerScope = customerScopeSearchParam;
        } else {
          customerScope = getCustomerScopes(tokenParsed).shift();
        }
      } else {
        // User is no ADMIN_USER or ADMIN_READER - only AGS or ZV available in groups allowed.
        const customerScopes = getCustomerScopes(tokenParsed);

        if (customerScopeSearchParam && customerScopes.includes(customerScopeSearchParam)) {
          customerScope = customerScopeSearchParam;
        } else {
          customerScope = customerScopes[0];
        }
      }
    } else {
      // When page is loaded and user is already authenticated, keycloak authorizes only with "openid" scope.
      // We authorize again with "groups" in scope to get token with groups claim.
      // Newer versions of keycloak library fix this
      // issue (https://github.com/keycloak/keycloak/commit/b926cd20f1e9f564d57619165d91c1600ad4299e), but we
      // have to stick with our current version for now because IDM doesn't offer newer versions.
      keycloak.login({ scope: "groups" });
    }
  }
  return customerScope || "";
}

export function getCustomerScopes(token?: { [prop: string]: any }): string[] {
  return getGroups(token)
    .filter((group) => group.startsWith(PREFIX_AGS) || group.startsWith(PREFIX_ZWECKVERBAND))
    .map((group) => transformGroupToCustomerScope(group))
    .filter(isString);
}

export function transformGroupToCustomerScope(group: string | null): string | undefined {
  if (!group) return undefined;
  if (group.startsWith(PREFIX_ZWECKVERBAND)) {
    // PAM-ZV-20800001_Laiern -> ZV_Laiern
    return "ZV_" + group.replace("PAM-ZV-", "").split("_")[1];
  }
  // PREFIX_AGS - only AGS left
  return group.replace("PAM-", "").split("_")[0].replace("-", "_");
}

function isString(value: string | undefined): value is string {
  return typeof value === "string";
}
