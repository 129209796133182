export enum InputChannel {
  CENTRAL_OFFICE = "CENTRAL_OFFICE",
  KOK = "KOK",
  FAX = "FAX",
  PHONE = "PHONE",
  EMAIL = "EMAIL",
  STOERUNG24 = "STOERUNG24",
  INTERNAL = "INTERNAL",
  LION = "LION",
  CITIZEN = "CITIZEN",
  OTHER = "OTHER",
};