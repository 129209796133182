import { Box, Typography } from "@mui/material";
import { Component } from "react";
import { GenericErrorType, isLionError } from "../../context/ErrorContext/LionError";
import { defaultErrorContents } from "../../context/ErrorContext/errorContent";

interface State {
  message?: string;
  hasError: boolean;
}

export class ErrorBoundary extends Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(err: any): State {
    let message = "";
    if (isLionError(err)) {
      if (err.errorType !== GenericErrorType.CRITICAL) {
        return {
          hasError: false,
        };
      }
      message = err.content?.message || defaultErrorContents[err.errorType].message;
    }
    return {
      hasError: true,
      message,
    };
  }

  componentDidCatch(err: Error, errorInfo: any) {
    console.log("ErrorBaoundary:", err, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box textAlign={"center"}>
          <Typography variant={"h1"} color="red" marginTop={"20%"} marginBottom={2}>
            Fehler
          </Typography>
          <Typography className="text-black" variant={"h3"} marginBottom={1}>
            Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.
          </Typography>
          {!this.state.message ? null : <Typography variant="inherit">{this.state.message}</Typography>}
        </Box>
      );
    }

    return this.props.children;
  }
}
