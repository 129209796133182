import { Paper, Typography } from "@mui/material";
import React from "react";
import { StatusIcon } from "../../assets/icons";
import { FaultReport, getReporterTypeLabel } from "../../models";
import theme from "../../theme";
import { UtcToLocale } from "../UtcToLocale/UtcToLocale";
import { determineStateIconColor, getStatusInOverviewLabel } from "../../models/IncidentStatus";

interface Props {
  report: FaultReport | null;
}

export const InfoBar = ({ report }: Props) => {
  return (
    <Typography component="span">
      <Paper style={infoBarContainerStyle} elevation={3}>
        <InfoBarSection label="Meldung vom" style={verticalDivider}>
          {/* the label and the relevant field depend on wether the fault report has been approved */}
          <strong>
            <UtcToLocale datetime={report?.reportingTimestamp} />
          </strong>
        </InfoBarSection>
        <InfoBarSection label="Bearbeitungsstatus" style={verticalDivider}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <StatusIcon color={report?.incidentStatus ? determineStateIconColor(report.incidentStatus) : "black"} />
            <strong style={{ marginLeft: 5 }}>
              {report?.incidentStatus ? getStatusInOverviewLabel(report.incidentStatus) : "-"}
            </strong>
          </div>
        </InfoBarSection>
        <InfoBarSection label="Melder" style={verticalDivider}>
          <strong>{report?.reporterType ? getReporterTypeLabel(report.reporterType) : "-"}</strong>
        </InfoBarSection>
      </Paper>
    </Typography>
  );
};

interface SectionProps {
  label: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}
const InfoBarSection = ({ label, children, style }: SectionProps) => {
  return (
    <div style={{ ...sectionContainerStyle, ...style }}>
      <Typography style={{ color: theme.palette.grey[500], marginBottom: "3px" }}>{label}</Typography>
      {children}
    </div>
  );
};

const sectionContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  flex: "1 1 0",
  padding: "0 20px",
};

const infoBarContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  padding: "20px 0",
};

const verticalDivider: React.CSSProperties = {
  borderRight: `1px solid ${theme.palette.grey[500]}`,
};
