import { Checkbox as MuiCheckbox, Typography, FormControlLabel, Box } from "@mui/material";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import { Symptom, SYMPTOM_LABEL } from "../../../models";
import { colors } from "../../../theme";
import { ImageUploader } from "./ImageUploader";
import { ImageIO } from "../../../dataQuery/imageApi";

interface SymptomSelectBoxValue {
  symptoms: Symptom[];
  symptomOther: string;
}

interface SymptomSelectBoxProps {
  value: SymptomSelectBoxValue;
  onSelectSymptoms: (s: Symptom[]) => void;
  onSymptomOther: (s: string) => void;
  onChangeImages: (i: ImageIO[]) => void;
  onShowSpinner: (v: boolean) => void;
}
export const SymptomSelectStep = (props: SymptomSelectBoxProps) => {
  const [symptomOther, setSymptomOther] = useState(props.value.symptomOther);

  const onCheckBoxChange = (checked: boolean, symptom: Symptom) => {
    const selectedSymptoms = checked
      ? [...props.value.symptoms, symptom]
      : props.value.symptoms.filter((s) => s !== symptom);

    props.onSelectSymptoms(selectedSymptoms);
  };

  function handleSymptomOtherChange(v: string) {
    setSymptomOther(v);
    props.onSymptomOther(v);
  }

  return (
    <Grid
      container
      spacing={2}
      style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
    >
      <Grid
        item
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" color={"textPrimary"} style={{ fontWeight: "bold", marginTop: 20 }}>
          Wählen Sie bitte eine / mehrere Störungen aus
        </Typography>
        <Grid container item margin={3}>
          {Object.values(Symptom).map((symptom, i) => (
            <Grid item xs={6} className="mb-3" key={symptom}>
              <CheckBox
                label={SYMPTOM_LABEL[symptom]}
                checked={props.value.symptoms.includes(symptom)}
                onChange={(checked) => onCheckBoxChange(checked, symptom)}
              />
            </Grid>
          ))}
        </Grid>
        <Typography variant="h5" color={"textPrimary"} style={{ fontWeight: "bold" }}>
          Haben Sie weitere Anmerkungen zu den Symptomen der Störung? <span style={{ fontWeight: "normal" }}>(Optional)</span>
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="end" style={{ width: "100%" }}>
          <TextField
            key="symptomOther"
            variant="standard"
            multiline
            fullWidth
            maxRows={4}
            style={{ marginTop: 10 }}
            placeholder="Anmerkung"
            inputProps={{ maxLength: 1000 }}
            value={symptomOther}
            onChange={(e) => handleSymptomOtherChange(e.target.value)}
          />
          <Typography color="textSecondary"> {symptomOther ? symptomOther?.length : 0} / 1000</Typography>
          <br />
          <ImageUploader onChangeImages={props.onChangeImages} onShowSpinner={props.onShowSpinner} />
        </Box>
      </Grid>
    </Grid>
  );
};

interface CheckBoxProps {
  label: string;
  onChange: (checked: boolean) => void;
  style?: React.CSSProperties;
  checked: boolean;
  className?: string;
}

const CheckBox = ({ onChange, ...formControlProps }: CheckBoxProps) => {
  return (
    <FormControlLabel
      {...formControlProps}
      control={<MuiCheckbox style={{ color: colors.secondary.main }} onChange={(e) => onChange(e.target.checked)} />}
    />
  );
};
