export const PlusIcon = () => (
  <svg width="23" height="23" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2277:32428)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.65397 3.84603V0H4.34628V3.84603H0.5V6.15372H4.34628V10H6.65397V6.15372H10.5V3.84603H6.65397Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2277:32428">
        <rect width="23" height="23" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
