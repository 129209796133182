import CSS from "csstype";
import theme from "../../theme";
import { CockpitIcon, ListIcon, ArchiveIcon, PlusIcon } from "../../assets/icons";
import { SidebarLink } from "./SidebarIcon";
import { useLocation } from "react-router-dom";
import { AppRoute, FaultReportRoutes, HomePageRoute } from "../../hooks/navigation/routes";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { InfoIcon } from "../../assets/icons/InfoIcon";

export interface SideBarLinkConfig {
  icon: JSX.Element;
  alt: string;
  target: AppRoute;
  disabled?: boolean;
  pathnames: string[];
}

export const Sidebar = () => {
  const { pathname } = useLocation();

  return (
    <div className="flex flex-col justify-between" style={containerStyle}>
      <div>
        {topIcons.map((icon, index) => (
            <SidebarLink
                key={index}
                icon={icon.icon}
                alt={icon.alt}
                target={icon.target}
                selected={icon.pathnames.includes(pathname)}
                disabled={icon.disabled}
            />
        ))}
      </div>
      <div>
        {bottomIcons.map((icon, index) => (
            <SidebarLink
                key={index}
                icon={icon.icon}
                alt={icon.alt}
                target={icon.target}
                selected={icon.pathnames.includes(pathname)}
                disabled={icon.disabled}
            />
        ))}
      </div>
    </div>
  );
};

const topIcons: SideBarLinkConfig[] = [
  { icon: <CockpitIcon />, alt: "cockpit-icon", target: AppRoute.COCKPIT, pathnames: Object.values(HomePageRoute) },
  {
    icon: <LibraryAddCheckIcon style={{ color: "#fff" }} />,
    alt: "list-icon",
    target: AppRoute.INAPPROVALVIEW,
    pathnames: [FaultReportRoutes.INAPPROVALVIEW],
  },
  {
    icon: <ListIcon />,
    alt: "list-icon",
    target: AppRoute.INPROGRESSVIEW,
    pathnames: [FaultReportRoutes.INPROGRESSVIEW],
  },
  {
    icon: <ArchiveIcon />,
    alt: "archive-icon",
    target: AppRoute.COMPLETEDVIEW,
    pathnames: [FaultReportRoutes.COMPLETEDVIEW],
  },
  { icon: <PlusIcon />, alt: "plus-icon", target: AppRoute.CREATE_REPORT, pathnames: [AppRoute.CREATE_REPORT] }
];

const bottomIcons: SideBarLinkConfig[] = [
  { icon: <InfoIcon />, alt: "information-icon", target: AppRoute.RELEASE_NOTES, pathnames: [AppRoute.RELEASE_NOTES] }
]

const containerStyle: CSS.Properties = {
  backgroundColor: theme.palette.primary.main,
  minWidth: "4rem",
};
