import { colors } from "../theme";

export type IncidentStatus =
  | "APPROVAL_REQUIRED_BY_COMMUNE"
  | "APPROVED_BY_COMMUNE"
  | "APPROVAL_REQUIRED_BY_NETZE_BW"
  | "NEW"
  | "ORDER_PLACED"
  // | "ORDER_SCHEDULED" This is commented out with ticket 3094387. It should be deleted completely if nothing will change after tests with users
  | "OVERDUE"
  | "CHECK_FEEDBACK"
  | "CHECK_FEEDBACK_FOLLOW_UP_MEASURES"
  | "INTERNAL_CLARIFICATION"
  | "FOLLOW_UP_MEASURES_CABLE_TEST_VAN"
  | "FOLLOW_UP_MEASURES_MATERIAL_PROCUREMENT"
  | "FOLLOW_UP_MEASURES_MORE_DEFICIENCIES"
  | "FOLLOW_UP_MEASURE_IN_PROGRESS"
  | "IMMINENT_DANGER"
  | "IMMINENT_DANGER_LIES_AT_SALES_DEPARTMENT"
  | "UNREPAIRABLE"
  | "REJECTED_BY_COMMUNE"
  | "REJECTED_BY_NETZE_BW"
  | "FAULT_FIXED"
  | "FAULT_FIXED_PROVISIONAL_SOLUTION"
  | "POLE_NOT_ACCESSIBLE"
  | "TRANSMITTED_TO_CONTROL_CENTER"
  | "DELETED"
  | "ORDER_MATERIAL_OPEN"
  | "ORDER_MATERIAL_CLOSED";

export type StatusGroup = {
  label: string,
  statuses: IncidentStatus[];
}

export const IncidentStatus = {
  ApprovalRequiredByCommune: "APPROVAL_REQUIRED_BY_COMMUNE" as IncidentStatus,
  ApprovedByCommune: "APPROVED_BY_COMMUNE" as IncidentStatus,
  ApprovalRequiredByNetzeBw: "APPROVAL_REQUIRED_BY_NETZE_BW" as IncidentStatus,
  New: "NEW" as IncidentStatus,
  OrderPlaced: "ORDER_PLACED" as IncidentStatus,
  // OrderScheduled: "ORDER_SCHEDULED" as IncidentStatus, This is commented out with ticket 3094387. It should be deleted completely if nothing will change after tests with users
  Overdue: "OVERDUE" as IncidentStatus,
  CheckFeedback: "CHECK_FEEDBACK" as IncidentStatus,
  CheckFeedbackFollowUpMeasures: "CHECK_FEEDBACK_FOLLOW_UP_MEASURES" as IncidentStatus,
  InternalClarification: "INTERNAL_CLARIFICATION" as IncidentStatus,
  FollowUpMeasuresCableTestVan: "FOLLOW_UP_MEASURES_CABLE_TEST_VAN" as IncidentStatus,
  FollowUpMeasuresMaterialProcurement: "FOLLOW_UP_MEASURES_MATERIAL_PROCUREMENT" as IncidentStatus,
  FollowUpMeasuresMoreDeficiencies: "FOLLOW_UP_MEASURES_MORE_DEFICIENCIES" as IncidentStatus,
  FollowUpMeasureInProgress: "FOLLOW_UP_MEASURE_IN_PROGRESS" as IncidentStatus,
  ImminentDanger: "IMMINENT_DANGER" as IncidentStatus,
  ImminentDangerLiesAtSalesDepartment: "IMMINENT_DANGER_LIES_AT_SALES_DEPARTMENT" as IncidentStatus,
  Unrepairable: "UNREPAIRABLE" as IncidentStatus,
  RejectedByCommune: "REJECTED_BY_COMMUNE" as IncidentStatus,
  RejectedByNetzeBw: "REJECTED_BY_NETZE_BW" as IncidentStatus,
  FaultFixed: "FAULT_FIXED" as IncidentStatus,
  FaultNotFound: "FAULT_NOT_FOUND" as IncidentStatus,
  FaultFixedProvisionalSolution: "FAULT_FIXED_PROVISIONAL_SOLUTION" as IncidentStatus,
  PoleNotAccessible: "POLE_NOT_ACCESSIBLE" as IncidentStatus,
  TransmittedToControlCenter: "TRANSMITTED_TO_CONTROL_CENTER" as IncidentStatus,
  Deleted: "DELETED" as IncidentStatus,
  OrderMaterialOpen: "ORDER_MATERIAL_OPEN" as IncidentStatus,
  OrderMaterialClosed: "ORDER_MATERIAL_CLOSED" as IncidentStatus,
};

export const getInProgressStatusGroups = (): StatusGroup[] => {
  const statusLabelsMap: { [label: string]: IncidentStatus[] } = {
    "Meldung übermittelt": [
        IncidentStatus.New
    ],
    "Störungsbehebung vorbereiten": [
        IncidentStatus.ApprovalRequiredByNetzeBw
    ],
    "Freigabe von Kommune": [
      IncidentStatus.ApprovedByCommune
    ],
    "Folgemaßnahmen (Materialbeschaffung)": [
        IncidentStatus.FollowUpMeasuresMaterialProcurement
    ],
    "Material wurde bestellt": [
        IncidentStatus.OrderMaterialOpen
    ],
    "Material wurde geliefert": [
        IncidentStatus.OrderMaterialClosed
    ],
    "Störungsbehebung beauftragt": [
        IncidentStatus.OrderPlaced
    ],
    "Störungsbehebung eingeplant": [
        IncidentStatus.Overdue
    ],
    "Durchführung Folgemaßnahmen": [
        IncidentStatus.CheckFeedbackFollowUpMeasures,
        IncidentStatus.FollowUpMeasureInProgress
    ],
    "Störungsaufnahme erfolgt": [
        IncidentStatus.CheckFeedback
    ],
    "Folgemaßnahmen erforderlich": [
        IncidentStatus.InternalClarification
    ],
    "Folgemaßnahmen (Kabelmesswagen)": [
        IncidentStatus.FollowUpMeasuresCableTestVan
    ],
    "Kontaktaufnahme durch Fachvertrieb": [
        IncidentStatus.Unrepairable
    ],
    "Gefahr im Verzug - Erstsicherung durchgeführt": [
        IncidentStatus.ImminentDanger
    ],
    "Gefahr im Verzug - Angebot Instandsetzung": [
        IncidentStatus.ImminentDangerLiesAtSalesDepartment
    ],
    "Folgemaßnahmen (weitere Mängel)": [
        IncidentStatus.FollowUpMeasuresMoreDeficiencies
    ]
  }

  const statusGroups: StatusGroup[] = [];

  for (const label in statusLabelsMap) {
    statusGroups.push({ label, statuses: statusLabelsMap[label] });
  }

  return statusGroups;
}

export const getCompletedStatusGroups = (): StatusGroup[] => {
  const statusLabelsMap: { [label: string]: IncidentStatus[] } = {
    "Störung behoben": [
        IncidentStatus.FaultFixed,
        IncidentStatus.FaultFixedProvisionalSolution
    ],
    "Keine Störung vorgefunden": [
      IncidentStatus.FaultNotFound,
    ],
    "Gelöscht": [
        IncidentStatus.Deleted
    ],
    "Abgelehnt durch Kommune": [
        IncidentStatus.RejectedByCommune
    ],
    "Abgelehnt durch Netze BW": [
        IncidentStatus.RejectedByNetzeBw
    ],
    "Mast nicht zugänglich (Folgemaßnahme Kommune)": [
        IncidentStatus.PoleNotAccessible
    ],
    "Falscher Meldeweg": [
        IncidentStatus.TransmittedToControlCenter
    ]
  }

  const statusGroups: StatusGroup[] = [];

  for (const label in statusLabelsMap) {
    statusGroups.push({ label, statuses: statusLabelsMap[label] });
  }

  return statusGroups;
}

export const getStatusInOverviewLabel = (incidentStatus: IncidentStatus) => {
  switch (incidentStatus) {
    case IncidentStatus.ApprovalRequiredByNetzeBw:
      return "Störungsbehebung vorbereiten";
    case IncidentStatus.New:
      return "Meldung übermittelt";
    case IncidentStatus.ApprovalRequiredByCommune:
      return "Freigabe erforderlich";
    case IncidentStatus.OrderPlaced:
      return "Störungsbehebung beauftragt";
    case IncidentStatus.ApprovedByCommune:
      return  "Freigabe von Kommune";
    /*
    This is commented out with ticket 3094387. It should be deleted completely if nothing will change after tests with users

    case IncidentStatus.OrderScheduled:
      return "Störungsbehebung eingeplant";
     */
    case IncidentStatus.Overdue:
      return "Störungsbehebung eingeplant";
    case IncidentStatus.CheckFeedback:
      return "Störungsaufnahme erfolgt";
    case IncidentStatus.CheckFeedbackFollowUpMeasures:
    case IncidentStatus.FollowUpMeasureInProgress:
      return "Durchführung Folgemaßnahmen";
    case IncidentStatus.InternalClarification:
      return "Folgemaßnahmen erforderlich";
    case IncidentStatus.FollowUpMeasuresCableTestVan:
      return "Folgemaßnahmen (Kabelmesswagen)";
    case IncidentStatus.FollowUpMeasuresMaterialProcurement:
      return "Folgemaßnahmen (Materialbeschaffung)";
    case IncidentStatus.OrderMaterialOpen:
      return "Material wurde bestellt";
    case IncidentStatus.OrderMaterialClosed:
      return "Material wurde geliefert";
    case IncidentStatus.FollowUpMeasuresMoreDeficiencies:
      return "Folgemaßnahmen (weitere Mängel)";
    case IncidentStatus.ImminentDanger:
      return "Gefahr im Verzug - Erstsicherung durchgeführt";
    case IncidentStatus.ImminentDangerLiesAtSalesDepartment:
      return "Gefahr im Verzug - Angebot Instandsetzung";
    case IncidentStatus.Unrepairable:
      return "Kontaktaufnahme durch Fachvertrieb";
    case IncidentStatus.RejectedByCommune:
      return "Abgelehnt durch Kommune";
    case IncidentStatus.RejectedByNetzeBw:
      return "Abgelehnt durch Netze BW";
    case IncidentStatus.FaultFixed:
    case IncidentStatus.FaultFixedProvisionalSolution:
      return "Störung behoben";
    case IncidentStatus.FaultNotFound:
      return "Keine Störung vorgefunden";
    case IncidentStatus.PoleNotAccessible:
      return "Mast nicht zugänglich (Folgemaßnahme Kommune)";
    case IncidentStatus.TransmittedToControlCenter:
      return "Falscher Meldeweg";
    case IncidentStatus.Deleted:
      return "Gelöscht";
    default:
      return "-";
  }
};

export const getStatusDescription = (incidentStatus: IncidentStatus) => {
  switch (incidentStatus) {
    case IncidentStatus.ApprovalRequiredByNetzeBw:
      return "Störungsmeldung wurde an Netze BW übermittelt. Ein Auftrag zur Störungsbehebung wird vorbereitet.";
    case IncidentStatus.New:
      return "Störungsmeldung wurde an Netze BW übermittelt.";
    case IncidentStatus.ApprovalRequiredByCommune:
      return "Die Bürgermeldung liegt zur Prüfung vor.";
    case IncidentStatus.ApprovedByCommune:
      return "Freigabe von Kommune";
    case IncidentStatus.OrderPlaced:
      return "Störungsbehebung wurde in Auftrag gegeben.";
      /*
      This is commented out with ticket 3094387. It should be deleted completely if nothing will change after tests with users

      case IncidentStatus.OrderScheduled:
        return "Störungsbehebung eingeplant";
       */
    case IncidentStatus.Overdue:
      return "Die Störungsbehebung wurde in eine Reparaturfahrt eingeplant.";
    case IncidentStatus.CheckFeedback:
      return "Die Störungsbehebung wird durchgeführt.";
    case IncidentStatus.CheckFeedbackFollowUpMeasures:
      return "Zur Durchführung der Folgemaßnahmen ist eine Rückmeldung eingegangen, welche geprüft wird.";
    case IncidentStatus.FollowUpMeasureInProgress:
      return "Die Folgemaßnahme wurde beauftragt und wird umgesetzt.";
    case IncidentStatus.InternalClarification:
      return "Eine mögliche Gefahrenstelle an der Leuchtstelle wurde gesichert. Folgemaßnahmen zur Wiederinbetriebnahme wurden in die Wege geleitet.";
    case IncidentStatus.FollowUpMeasuresCableTestVan:
      return "Eine mögliche Gefahrenstelle an der Leuchtstelle wurde gesichert. Für die Störungsfindung wird ein Kabelmesswagen benötigt.";
    case IncidentStatus.FollowUpMeasuresMaterialProcurement:
      return "Eine mögliche Gefahrenstelle an der Leuchtstelle wurde gesichert. Für die Wiederinbetriebnahme muss zusätzliches Material beschafft werden.";
    case IncidentStatus.OrderMaterialOpen:
      return "Das Material ist aktuell nicht vorrätig und musste für die die Störungsbehebung bestellt werden.";
    case IncidentStatus.OrderMaterialClosed:
      return "Das bestellte Material wurde geliefert und ist nun verfügbar. Die Störungsbehebung wird initiiert.";
    case IncidentStatus.FollowUpMeasuresMoreDeficiencies:
      return "Eine mögliche Gefahrenstelle und potenzielle Störung an der Leuchtstelle wurde gesichert. Es sind weitere Mängel festgestellt worden, die zu beseitigen sind.";
    case IncidentStatus.ImminentDanger:
      return "Eine mögliche Gefahrenstelle an der Leuchtstelle wurde gesichert. Falls weitere Maßnahmen erforderlich sind, wird der Fachvertrieb sich melden.";
    case IncidentStatus.ImminentDangerLiesAtSalesDepartment:
      return "Eine mögliche Gefahrenstelle an der Leuchtstelle wurde gesichert. Der Fachvertrieb wird sich zeitnah melden.";
    case IncidentStatus.Unrepairable:
      return "Das vorgefundene Material bei der Störungsmeldung ist irreparabel. Aus diesem Grund wird sich unser Fachvertrieb bei Ihnen mit näheren Informationen melden.";
    case IncidentStatus.RejectedByCommune:
      return "Die Bürgermeldung wurde durch Kommune abgelehnt.";
    case IncidentStatus.RejectedByNetzeBw:
      return "Die Störungsmeldung wurde durch Netze BW abgelehnt.";
    case IncidentStatus.FaultFixed:
    case IncidentStatus.FaultFixedProvisionalSolution:
      return "Die Störung wurde behoben.";
    case IncidentStatus.FaultNotFound:
      return "Vor Ort wurde keine Störung vorgefunden.";
    case IncidentStatus.PoleNotAccessible:
      return "Der Mast war für eine Störungsbehebung nicht zugänglich. Bitte stellen Sie sicher, dass der Mast zugänglich ist und melden Sie die Störung erneut, sobald dies der Fall ist.";
    case IncidentStatus.TransmittedToControlCenter:
      return "Störungen, welche eine Gefahr für den Bürger darstellen müssen unverzüglich telefonisch an die Leitstelle gemeldet werden. Ihre Meldung wurde durch uns übermittelt.";
    case IncidentStatus.Deleted:
      return "Die Meldung wurde gelöscht.";
    default:
      return "-";
  }
};

export const isStatusInProgress = (incidentStatus: IncidentStatus) => {
  if (InProgressStatus.includes(incidentStatus)) {
    return true;
  }

  return false;
};

export const InProgressStatus: IncidentStatus[] = [
  IncidentStatus.ApprovalRequiredByNetzeBw,
  IncidentStatus.ApprovedByCommune,
  // IncidentStatus.ApprovalRequiredByCommune,
  IncidentStatus.New,
  IncidentStatus.OrderPlaced,
  // IncidentStatus.OrderScheduled, This is commented out with ticket 3094387. It should be deleted completely if nothing will change after tests with users
  IncidentStatus.Overdue,
  IncidentStatus.CheckFeedback,
  IncidentStatus.CheckFeedbackFollowUpMeasures,
  IncidentStatus.InternalClarification,
  IncidentStatus.FollowUpMeasuresCableTestVan,
  IncidentStatus.FollowUpMeasuresMaterialProcurement,
  IncidentStatus.FollowUpMeasuresMoreDeficiencies,
  IncidentStatus.FollowUpMeasureInProgress,
  IncidentStatus.ImminentDanger,
  IncidentStatus.ImminentDangerLiesAtSalesDepartment,
  IncidentStatus.Unrepairable,
  IncidentStatus.OrderMaterialOpen,
  IncidentStatus.OrderMaterialClosed
];

export const isStatusComplete = (incidentStatus: IncidentStatus) => {
  return !isStatusInProgress(incidentStatus);
};

export const determineStateIconColor = (incidentStatus: IncidentStatus) => {
  if (incidentStatus === IncidentStatus.ApprovalRequiredByCommune) {
    return colors.status.approvalPending;
  }
  if (isStatusInProgress(incidentStatus)) {
    return colors.status.inProgress;
  }
  if (isStatusComplete(incidentStatus)) {
    return colors.status.completed;
  }
  return colors.status.inProgress;
};
