import React, {useEffect, useState} from 'react';
import ReleaseNotesData from '../../assets/ReleaseNotes.json';
import {ReleaseNote} from "../../models/ReleaseNote";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Link
} from "@mui/material";
import {Check, ExpandMore, NewReleases} from "@mui/icons-material";

export const ReleaseNotes = () => {
    const [releases, setReleases] = useState<ReleaseNote[]>([]);
    const [expandedPanel, setExpandedPanel] = useState(null);

    useEffect(() => {
        const sortedReleases = (ReleaseNotesData as ReleaseNote[]).sort((a, b) => (a.ordinal < b.ordinal ? 1 : -1));
        setReleases(sortedReleases);
    }, []);

    const handlePanelChange = (panelId) => (event, isExpanded) => {
        setExpandedPanel(isExpanded ? panelId : null);
    }

    return (
        <div className="container">
            <div className="page-header">
                <Typography variant="h1">
                    Release Notes
                </Typography>
            </div>
            <br />
            <div className="information" style={{ marginBottom: '20px' }}>
                <Typography variant="body1">
                    Nachfolgend finden Sie eine Übersicht über die Releases in der Applikation Straßenbeleuchtung mit den jeweiligen Änderungen/Erweiterungen.
                </Typography>
                <br />
                <Typography variant="body1">
                    Bei Fragen oder Anregungen nutzen Sie bitte unser Kontaktformular
                </Typography>
            </div>
            {releases.map((rel) => (
                <Accordion key={rel.id} style={{ marginBottom: '15px' }} expanded={expandedPanel === rel.id} onChange={handlePanelChange(rel.id)}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <NewReleases style={{ marginRight: '8px' }} color={expandedPanel === rel.id ? 'secondary' : 'primary' }/>
                        <Typography style={{
                            paddingLeft: '5px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            minWidth: '350px',
                            marginLeft: '10px'
                        }}><b>{rel.name}</b></Typography>
                        <Typography style={{
                            paddingLeft: '5px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            minWidth: '350px',
                            marginLeft: '10px'
                        }}>{rel.date}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {rel.features && (
                            <>
                                <Typography variant="h2">Neue Funktionen für das Störungsmanagement</Typography>
                                <List>
                                    {rel.features.map((feature, index) => (
                                        <ListItem key={index} style={{ padding: '0' }}>
                                            <ListItemIcon><Check color="secondary"/></ListItemIcon>
                                            <Typography variant="body1">
                                                <ListItemText primary={feature.text} />
                                            </Typography>
                                            {feature.link && (
                                                <Typography variant="body1" style={{ margin: '0 0.25rem 0 0.25rem'}}>
                                                    <Link href={feature.link.url} target="_blank">
                                                        <Typography className="font-semibold">
                                                            {feature.link.text}
                                                        </Typography>
                                                    </Link>
                                                </Typography>
                                            )}
                                            {feature.additionalText && (
                                                <Typography variant="body1">
                                                    <ListItemText primary={feature.additionalText} />
                                                </Typography>
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )}
                        {rel.improvements && (
                            <>
                                <Typography variant="h2">Verbesserungen im Störungsmanagement</Typography>
                                <List>
                                    {rel.improvements.map((improvement, index) => (
                                        <ListItem key={index} style={{ padding: '0' }}>
                                            <ListItemIcon><Check color="secondary"/></ListItemIcon>
                                            <Typography variant="body1">
                                                <ListItemText primary={improvement.text} />
                                            </Typography>
                                            {improvement.link && (
                                                <Typography variant="body1" style={{ margin: '0 0.25rem 0 0.25rem'}}>
                                                    <Link href={improvement.link.url} target="_blank">
                                                        <Typography className="font-semibold">
                                                            {improvement.link.text}
                                                        </Typography>
                                                    </Link>
                                                </Typography>
                                            )}
                                            {improvement.additionalText && (
                                                <Typography variant="body1">
                                                    <ListItemText primary={improvement.additionalText} />
                                                </Typography>
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )}
                        {rel.bugfixes && (
                            <>
                                <Typography variant="h2">Bugfixes im Störungsmanagement</Typography>
                                <List>
                                    {rel.bugfixes.map((bugfix, index) => (
                                        <ListItem key={index} style={{ padding: '0' }}>
                                            <ListItemIcon><Check color="secondary"/></ListItemIcon>
                                            <Typography variant="body1">
                                                <ListItemText primary={bugfix.text} />
                                            </Typography>
                                            {bugfix.link && (
                                                <Typography variant="body1" style={{ margin: '0 0.25rem 0 0.25rem'}}>
                                                    <Link href={bugfix.link.url} target="_blank">
                                                        <Typography className="font-semibold">
                                                            {bugfix.link.text}
                                                        </Typography>
                                                    </Link>
                                                </Typography>
                                            )}
                                            {bugfix.additionalText && (
                                                <Typography variant="body1">
                                                    <ListItemText primary={bugfix.additionalText} />
                                                </Typography>
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )}
                        {rel.detailsLink && (
                            <Typography variant="body1">
                                Detaillierte Informationen zum Release finden Sie{' '}
                                <Link href={rel.detailsLink} target="_blank">
                                    hier
                                </Link>
                            </Typography>
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};
