import { Box, Typography, Card } from "@mui/material";
import { ArrowIcon } from "../../../assets/icons";
import { ListElementSkeleton } from "./ListElementSkeleton";

interface Props {
  icon: JSX.Element;
  label: string;
  count: number | string | undefined;
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

const DashboardListElement = ({ icon, label, count, onClick, disabled, isLoading }: Props) => {
  return (
    <Card variant="outlined" onClick={onClick} style={{ cursor: !disabled ? "pointer" : "not-allowed", padding: 10 }}>
      {isLoading ? (
        <ListElementSkeleton />
      ) : (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box width="28px" display="flex" justifyContent="center" alignItems="center" marginRight={"5px"}>
            {icon}
          </Box>
          <Box display="flex" flexDirection="column" flex={1}>
            <Typography>{label}</Typography>
            <Typography variant="h2" color="textPrimary">
              <strong>{count ?? 0}</strong>
            </Typography>
          </Box>
          <Box>{ArrowIcon()}</Box>
        </Box>
      )}
    </Card>
  );
};

export default DashboardListElement;
