export const UserIcon = (color: any = "#000099", size: number | string = 30) => {
  return (
    <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill={color} />
      <path
        d="M11.1982 28.499C10.8666 25.3095 13.1115 21.7599 19.183 21.7599H19.8208C25.8923 21.7599 28.1118 25.3095 27.8056 28.499C27.8056 28.499 27.8056 28.8334 19.5147 28.8334C11.2237 28.8334 11.1982 28.499 11.1982 28.499Z"
        fill="white"
      />
      <path
        d="M19.4881 19.8318C21.9678 19.8318 23.978 17.805 23.978 15.3048C23.978 12.8046 21.9678 10.7778 19.4881 10.7778C17.0084 10.7778 14.9983 12.8046 14.9983 15.3048C14.9983 17.805 17.0084 19.8318 19.4881 19.8318Z"
        fill="white"
      />
    </svg>
  );
};
