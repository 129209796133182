import {Alert, Box, Button, Paper, Typography} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate, useParams } from "react-router-dom";
import {FaultReport, getIssueTypeLabel, SYMPTOM_LABEL} from "../../models";
import { useAppContext } from "../../context";
import { Main } from "../../layout";
import { InfoBar } from "./InfoBar";
import { InfoCard } from "./InfoCard";
import { InfoCardSection } from "./InfoCardSection";
import { UtcToLocale } from "../UtcToLocale";
import { ApproveCitizenReportBox } from "./ApproveCitizenFaultReport";
import { SnackBar } from "../../parts";
import { useFaultReportsApi } from "../../dataQuery";
import { useFaultReport } from "../../dataQuery/faultReportsApi/useFaultReport";
import { useEffect, useState } from "react";
import { useEnersisGeoMap } from "../../context/EnersisGeoMapContext";
import { IncidentStatus, getStatusInOverviewLabel } from "../../models/IncidentStatus";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {FaultLocationDialog} from "./FaultLocationDialog";

let approvedOrRejectedInfo: ApprovedOrRejectedInfo | undefined;

export function DetailedFaultReport() {
  const { id: reportId } = useParams<{ id: string }>();
  const { customerScope, setIsLoading } = useAppContext();
  const navigate = useNavigate();
  const { data: report, isLoading: loading, error, refetch: refetchFaultReport } = useFaultReport(reportId);
  const faultReportsApi = useFaultReportsApi();
  const [approvalResponseMessage, setApprovalResponseMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState<"error" | "success" | "info">();
  const [openLocationDialog, setOpenLocationDialog] = useState(false);
  const { loadLightpoints, searchLightPointsWithNumber } = useEnersisGeoMap();
  const [mapCenter, setMapCenter] = useState<[number, number]>([9.14, 48.93919]);
  const [isLightPointLocationCorrect, setLightPointLocationCorrect] = useState<boolean>(true);

  const handleSetApprovalState = async (approved: boolean, remarks?: string) => {
    if (!customerScope || !reportId) return;
    try {
      setIsLoading(true);
      await faultReportsApi.setApprovalState(reportId, { approved, remarks });
      if (report) {
        loadLightpoints();
      }
      setSnackbarVariant("success");
      approved
        ? setApprovalResponseMessage(
            "Die Meldung wurde erfolgreich weitergeleitet und liegt nun der Netze BW zur weiteren Verarbeitung vor."
          )
        : setApprovalResponseMessage("Die Meldung wurde archiviert");
      await refetchFaultReport();
    } catch (error) {
      setSnackbarVariant("error");
      setApprovalResponseMessage("Etwas ist schiefgelaufen. Bitte versuchen Sie es später nochmal.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  function handleOnGoBack() {
    navigate(-1);
  }

  const getLightPointByLightingNo = async (lightPointNumber: string) => {
    if (!searchLightPointsWithNumber) {
      return;
    }

    const result = await searchLightPointsWithNumber(lightPointNumber);
    if (result.totalCount === 0) {
      return;
    }
    if (result.totalCount === 1) {
      return result.data[0];
    }
  };

  const onLocationDialogOpen = async () => {
    setIsLoading(true);
    const lightPoint = await getLightPointByLightingNo(report?.lightingNo || "");
    if (lightPoint?.longitude && lightPoint?.latitude) {
      setMapCenter([lightPoint.longitude, lightPoint.latitude]);
      setOpenLocationDialog(true);
    } else {
      setLightPointLocationCorrect(false);
    }
    setIsLoading(false);
  }

  const onLocationDialogClose = () => {
    setOpenLocationDialog(false);
  };

  useEffect(() => setIsLoading(loading), [loading, setIsLoading]);

  return (
    <Main>
      <Box className="flex mb-4" alignItems="verticalAlign">
        <Typography variant="h2">Störungsmeldung</Typography>
        <Typography variant="h2" color="textPrimary" className="pl-1">
          {report?.lightingNo}
        </Typography>
      </Box>
      {error ? (
        <ErrorBox />
      ) : (
        <div className="flex flex-col gap-5">
          <ApproveCitizenReportBox incidentStatus={report?.incidentStatus} onSubmit={handleSetApprovalState} />
          <InfoBar report={report || null}></InfoBar>
          <div className="flex gap-5">
            <InfoCard label="Störung" className="w-1/3">
              <InfoCardSection label="Gemeldet am">
                <Typography>
                  <UtcToLocale datetime={report?.reportingTimestamp} />
                </Typography>
              </InfoCardSection>
              <InfoCardSection label="Störung aufgefallen am">
                <Typography>
                  <UtcToLocale datetime={report?.recognitionDateTime} />
                </Typography>
              </InfoCardSection>
              <InfoCardSection label="Art der Störung">
                <span>{report?.issueType ? getIssueTypeLabel(report?.issueType) : "-"}</span>
              </InfoCardSection>
              <InfoCardSection label="Symptome">
                <ul className="m-0 pl-5">
                  {report?.symptoms?.map((symptom, i) => (
                    <li key={symptom}>{SYMPTOM_LABEL[symptom]}</li>
                  ))}
                </ul>
              </InfoCardSection>
              <InfoCardSection label="Anmerkung zu den Symptomen">
                <Typography>{report?.symptomOther || "-"}</Typography>
              </InfoCardSection>
            </InfoCard>
            <InfoCard label="Meldung" className="w-1/3">
              <InfoCardSection label="Anmerkung des Bürgers">
                <span>{report?.remarksCitizen}</span>
              </InfoCardSection>
              {approvedOrRejectedInfo && (
                <InfoCardSection label={approvedOrRejectedInfo.title}>
                  <Typography>{approvedOrRejectedInfo.subject}</Typography>
                </InfoCardSection>
              )}
              <InfoCardSection label="Anmerkung der Kommune">
                <Typography>{report?.remarksMunicipality || "-"}</Typography>
              </InfoCardSection>
              <InfoCardSection label="Melderdaten">{renderReporter(report || undefined)}</InfoCardSection>
              <InfoCardSection label="Statushistorie">
                <ul className="m-0 pl-5">
                  {report?.journal?.map((journal, i) => (
                    <li key={journal.createdDateTime}>
                      <UtcToLocale datetime={journal?.createdDateTime} /> :{" "}
                      {getStatusInOverviewLabel(journal.state as IncidentStatus)}
                    </li>
                  ))}
                </ul>
              </InfoCardSection>
            </InfoCard>
            <InfoCard label="Ort" className="w-1/3">
              <InfoCardSection label="Ort der Störung">
                <Typography>
                  {report?.street} {report?.houseNo}
                  <br />
                  {report?.zipCode} {report?.city}
                </Typography>
              </InfoCardSection>
              <InfoCardSection label="Anmerkung zum Ort der Störung">-</InfoCardSection>
              <div className="text-left">
                <Button variant="text" color="inherit" onClick={onLocationDialogOpen}>
                  <MapOutlinedIcon color="warning" /> Auf der Karte anzeigen
                </Button>
                {!isLightPointLocationCorrect && (
                    <Alert severity="error" sx={{ marginY: "20px" }}>
                      Leider fehlen die entsprechenden Koordinaten in der Datenbank zur Anzeige der Leuchtstelle.
                      <br /><br />
                      Bitte geben Sie uns eine Rückmeldung dazu mit der entsprechenden Leuchtstellennummer über das Kontaktformular.
                      <br /><br />
                      Wir werden die Daten entsprechend ergänzen. Vielen Dank.
                    </Alert>
                )}
                <FaultLocationDialog
                    mapCenter={mapCenter}
                    lightingNo={report?.lightingNo}
                    open={openLocationDialog}
                    onDialogClose={onLocationDialogClose}
                />
              </div>
            </InfoCard>
          </div>
          <div className="text-left">
            <Button color="secondary" size="large" onClick={handleOnGoBack}>
              Zurück
            </Button>
          </div>
        </div>
      )}
      <SnackBar
        open={!!approvalResponseMessage}
        variant={snackbarVariant}
        onClose={() => setApprovalResponseMessage("")}
        message={approvalResponseMessage}
      />
    </Main>
  );
}

interface ApprovedOrRejectedInfo {
  title: "Freigegeben von" | "Abgelehnt von";
  subject: string;
}

function renderReporter(report?: FaultReport) {
  if (!report?.reporter) {
    return <Typography>-</Typography>;
  }
  let reporterName = report?.reporter?.firstName || "";
  if (report?.reporter?.lastName) {
    reporterName += " " + report?.reporter?.lastName;
  }
  return (
    <>
      {reporterName && <Typography>{reporterName}</Typography>}
      {report.reporter.email && <Typography>{report.reporter.email}</Typography>}
    </>
  );
}

const ErrorBox = () => (
  <Box className="flex flex-auto justify-center">
    <Paper className="w-full p-4" elevation={24}>
      <Typography variant="h3" color="error">
        <ErrorOutlineIcon className="align-middle" /> Störungsmeldung nicht gefunden!
      </Typography>
    </Paper>
  </Box>
);
