import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { LightPoint } from "../../../models";
import { LightPointUtils } from "../../../utils/lightPointUtils";
import {LongLatCoordinates} from "../../GeoMapEnersis/GeoMap.types";

interface LightPointSelectDialogProps {
  onClose: () => void;
  lightPoints: LightPoint[];
  centerCoordinate: LongLatCoordinates;
  onSelect: (l: LightPoint, c: LongLatCoordinates) => void;
}

export const LightPointSelectDialog = (props: LightPointSelectDialogProps) => (
  <Dialog onClose={props.onClose} open={props.lightPoints.length > 0}>
    <DialogTitle>Bitte wählen Sie die gewünschte Leuchtstelle aus</DialogTitle>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nummer</TableCell>
          <TableCell>Adresse</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.lightPoints.map((lightpoint) => (
          <TableRow
            key={lightpoint.number}
            className="cursor-pointer"
            hover
            role="button"
            onClick={() => props.onSelect(lightpoint, props.centerCoordinate)}
          >
            <TableCell>{lightpoint.number}</TableCell>
            <TableCell>{LightPointUtils.getLightPointAddress(lightpoint)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <DialogActions className="mt-4">
      <Button onClick={props.onClose} color="inherit" variant="contained">
        Schließen
      </Button>
    </DialogActions>
  </Dialog>
);
