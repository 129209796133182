import { Box } from "@mui/material";
import { Skeleton } from "@mui/material";
import React from "react";

export const ListElementSkeleton = () => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Skeleton variant="circular" width={28} height={28} style={{ marginRight: 5 }} />
      <Box display="flex" flexDirection="column" flex={1}>
        <Skeleton variant="text" width={200} height={20} />
        <Skeleton variant="rectangular" width={25} height={25} />
      </Box>
    </Box>
  );
};
