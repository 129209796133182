import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { TextField, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { colors } from "../../../theme";
import { ApproveCitizenIcon, RejectCitizenIcon } from "../../../assets/icons";
import { Close } from "@mui/icons-material";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (approved: boolean, remarks?: string) => void;
  variant: "reject" | "approve";
}
interface ModalHeaderProps {
  title: string;
  icon: JSX.Element;
}

export const CitizenReportDialog = ({ open, onClose, onSubmit, variant = "approve" }: Props) => {
  const [remarks, setRemarks] = useState<string>("");

  const DialogHeader = ({ title, icon }: ModalHeaderProps) => {
    return (
      <DialogTitle style={{ backgroundColor: colors.grey[100] }}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="row" alignItems="center">
            {icon}
            <Typography color="primary" variant="h3" style={{ marginLeft: "20px" }}>
              <strong>{title}</strong>
            </Typography>
          </Box>
          <Close style={{ cursor: "pointer" }} onClick={onClose} />
        </Box>
      </DialogTitle>
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader
        title={variant === "approve" ? "Störungsmeldung freigeben" : "Störungsmeldung ablehnen"}
        icon={variant === "approve" ? <ApproveCitizenIcon /> : <RejectCitizenIcon />}
      />
      <DialogContent>
        <Box margin="10px">
          {variant === "approve" ? (
            <>
              <TextField
                variant="standard"
                fullWidth
                multiline
                maxRows={4}
                style={{ marginTop: "10px", marginBottom: "40px" }}
                placeholder="Anmerkung an die Netze BW (Optional)"
                inputProps={{ maxLength: 500 }}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </>
          ) : (
            <>
              <Typography color="primary" style={{ marginBottom: 40 }}>
                <strong>Die Meldung wird nicht zur Bearbeitung an Netze BW weitergeleitet, sondern archiviert.</strong>
              </Typography>
            </>
          )}
          <Box display="flex" justifyContent="space-between" marginBottom="20px">
            <Button
              variant="contained"
              style={{
                width: "200px",
                backgroundColor: colors.common.white,
                border: `1px solid ${colors.secondary.main}`,
                marginRight: "10px",
                color: colors.secondary.main,
              }}
              onClick={onClose}
            >
              ABBRECHEN
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{
                width: "200px",
              }}
              onClick={() => onSubmit(variant === "approve" ? true : false, remarks)}
            >
              {variant === "approve" ? "JETZT FREIGEBEN" : "JETZT ABLEHNEN"}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default CitizenReportDialog;
