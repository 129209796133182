import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getReporterTypeLabel, FaultReport } from "../../models";
import theme from "../../theme";
import CSS from "csstype";
import { StatusIcon } from "../../assets/icons";
import { InfoIcon } from "../../assets/icons/InfoIcon";
import { UtcToLocale } from "../UtcToLocale/UtcToLocale";
import { useAppContext } from "../../context";
import { useMemo, useState } from "react";
import { useAppNavigate } from "../../hooks/navigation/useAppNavigate";
import { FaultStateVariant } from "../../hooks/navigation/routes";
import { IncidentStatus, determineStateIconColor, getStatusInOverviewLabel, getStatusDescription } from "../../models/IncidentStatus";

export function FaultReportTableRow({
  report,
  variant,
}: {
  report: FaultReport;
  variant: FaultStateVariant;
}): JSX.Element {
  const navigate = useAppNavigate();
  const { setDisplayingReport } = useAppContext();

  const [isPopupOpen, setPopupOpen] = useState(false);

  function handleOnClick() {
    setDisplayingReport(report);
    navigate({ id: report.uuid });
  }

  const handleMouseEnter = (event: React.MouseEvent) => {
      setPopupOpen(true);
  };

  const handleMouseLeave = () => {
      setPopupOpen(false);
  };

  return (
    <TableRow hover component="tr" style={{ cursor: "pointer" }} onClick={handleOnClick}>
      <TableCell>
        <Link to={`/meldungen/${report.uuid}`} style={lightpointStyle}>
          {report.lightingNo || report.controlCabinetNo || "-"}
        </Link>
      </TableCell>
      <TableCell>{report.cityDistrict || " "}</TableCell>
      <TableCell>{[report.street, report.houseNo].join(" ")}</TableCell>
      <TableCell>
        <UtcToLocale datetime={report.reportingTimestamp} />
      </TableCell>
      <TableCell>{getReporterTypeLabel(report.reporterType)}</TableCell>
      {variant === FaultStateVariant.INAPPROVALVIEW || variant === FaultStateVariant.INPROGRESSVIEW ? (
        <>
            <TableCell onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {isPopupOpen && (
                    <StateCellPopup status={report.incidentStatus} />
                )}
                <StateCellContent status={report.incidentStatus} />
            </TableCell>
        </>
      ) : (
        <>
          <TableCell>{report.completedAt ? <UtcToLocale datetime={report.completedAt} /> : null}</TableCell>
          <TableCell onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
              {isPopupOpen && (
                  <StateCellPopup status={report.incidentStatus} />
              )}
              <StateCellContent status={report.incidentStatus} />
          </TableCell>
        </>
      )}
    </TableRow>
  );
}

function StateCellContent({ status }: { status: IncidentStatus }) {
  return (
    <Box display="flex" flex="auto" alignItems="center">
      <StatusCellContent status={status}/>
      <InfoIcon />
    </Box>
  );
}

function StatusCellContent({ status }: { status: IncidentStatus }) {
    return (
        <Box display="flex" flex="auto" alignItems="center">
            <StatusIcon color={determineStateIconColor(status)} />
            <Typography>{getStatusInOverviewLabel(status)}</Typography>
        </Box>
    );

}

function StateCellPopup({ status }: { status: IncidentStatus }) {
  return (
    <Box style={{...popupStyle, ...lightpointStyle}} >
       <Typography>{getStatusDescription(status)}</Typography>
    </Box>
  );
}

const popupStyle: CSS.Properties = {
    position: "absolute",
    width: "15rem",
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.grey["900"],
    padding: "5px",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
    left: "100%"
};


const lightpointStyle: CSS.Properties = {
  textDecoration: "none",
  color: theme.palette.text.primary,
  fontWeight: "bold",
};
