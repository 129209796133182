import DashboardList from "./DashboardCardList/DashboardList";
import { EmergencyHintBox, NewFaultReportButton } from "../../parts";
import { Typography } from "@mui/material";
import {Box} from "@mui/system";

const statusTransparencyUrl = "https://assets.ctfassets.net/4eqhtukq2r3z/5GGeqMHdeVk6BbukOb0Ywe/12fc02a04835f281a602e152466e9c2a/Optimierungen_Statustransparenz_Modul_Stra_enbeleuchtung.pdf";

export function FaultReportSummary() {
    return (
        <>
            <Box>
                <Typography variant="h1" color="primary">
                    Cockpit
                </Typography>
                <Typography variant="h6" color="secondary">
                    <Box display="inline" fontWeight="bold">Verbesserung der Statustransparenz.
                        <a style={{marginLeft: 5}} href={statusTransparencyUrl} target="_blank" rel="noopener noreferrer">Mehr erfahren </a>
                    </Box>
                </Typography>
            </Box>
            <DashboardList/>
            <NewFaultReportButton/>
            <EmergencyHintBox detailedContent/>
        </>
    );
}
