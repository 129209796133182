import { Box, Grid, Typography } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { useState } from "react";

interface SaveReportBoxValue {
  recognitionDateTime: Date;
  remarks: string;
}

interface SaveReportBoxProps {
  value: SaveReportBoxValue;
  onRecognitionDateTimeChange: (d: Date) => void;
  onChangeRemarksMunicipality: (v: string) => void;
}
export const SaveReportStep = (props: SaveReportBoxProps) => {
  const [recognitionDateTime, setRecognitionDateTime] = useState(props.value.recognitionDateTime);

  const [remarks, setRemarks] = useState(props.value.remarks);

  function handleRecognitionDateTimeChange(d: Date | null) {
    if (!d) return;
    setRecognitionDateTime(d);
    props.onRecognitionDateTimeChange(d);
  }

  function handleRemarksChange(v: string) {
    setRemarks(v);
    props.onChangeRemarksMunicipality(v);
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid
        item
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-d",
        }}
      >
        <Typography variant="h4" color={"textPrimary"} style={{ fontWeight: "bold", marginBottom: 20 }}>
          Wann wurde die Störung vor Ort erkannt? <span style={{ fontWeight: "normal" }}>(Optional)</span>
        </Typography>
        <Box display="flex">
          <DatePicker
            mask={"__.__.____"}
            label="Datum"
            disableFuture
            value={recognitionDateTime}
            onChange={handleRecognitionDateTimeChange}
            renderInput={(params) => <TextField variant="standard" style={{ width: "40%" }} {...params} />}
          />
          <TimePicker
            value={recognitionDateTime}
            ampm={false}
            label="Uhrzeit"
            onChange={handleRecognitionDateTimeChange}
            renderInput={(params) => (
              <TextField variant="standard" style={{ width: "40%", marginLeft: 10 }} {...params} />
            )}
          />
          <div style={{ flex: 1 }}></div>
        </Box>
        <Typography variant="h4" color={"textPrimary"} style={{ fontWeight: "bold", marginTop: 40 }}>
          Weitere Anmerkungen <span style={{ fontWeight: "normal" }}>(Optional)</span>
        </Typography>
        <TextField
          variant="standard"
          fullWidth
          multiline
          maxRows={4}
          style={{ marginTop: 10 }}
          placeholder="Anmerkung"
          inputProps={{ maxLength: 500 }}
          value={remarks}
          onChange={(e) => handleRemarksChange(e.target.value)}
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography color="textSecondary"> {remarks ? remarks?.length : 0} / 500</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
