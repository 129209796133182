import { Snackbar, Button } from "@mui/material";
import { colors } from "../theme";
interface Props {
  open: boolean;
  onClose: (open: boolean) => void;
  message: string;
  variant?: "success" | "error" | "info";
  autoHideDuration?: number;
}

export const SnackBar = ({ open, onClose, message, variant = "success", autoHideDuration = 5000 }: Props) => {
  return (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      ContentProps={{ style: { backgroundColor: getColor(variant) } }}
      message={message}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={() => onClose(!open)}
      action={
        <Button
          style={{
            backgroundColor: getColor(variant),
            border: `1px solid ${colors.common.white}`,
            color: colors.common.white,
          }}
          onClick={() => onClose(!open)}
        >
          OK
        </Button>
      }
    />
  );
};

const getColor = (variant?: "success" | "error" | "info") => {
  switch (variant) {
    case "success":
      return colors.success.main;
    case "error":
      return colors.error.main;
    case "info":
      return colors.info.main;
  }
};

export default SnackBar;
