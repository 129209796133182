import { Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Theme, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/lab";
import { useFaultReportContext } from "../../context/FaultReportContext";
import { endOfDay, startOfDay } from "date-fns";
import { AsyncTextField } from "../AsyncTextField";
import { FaultStateVariant } from "../../hooks/navigation/routes";
import {
  IncidentStatus,
  getInProgressStatusGroups, getCompletedStatusGroups, StatusGroup,
} from "../../models/IncidentStatus";
import { useCallback, useEffect } from "react";

interface Props {
  variant: FaultStateVariant;
}

function getStyles(statuses: IncidentStatus[], selectedIncidentStatus: readonly IncidentStatus[], theme: Theme) {
  const isSelected = statuses.some(status => selectedIncidentStatus.includes(status));
  return {
    fontWeight:
      isSelected
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function FaultReportsFilter({ variant }: Readonly<Props>) {
  const { currentFilter: filter, updateFilter } = useFaultReportContext();
  const resetFilters = useResetFetchFilters(variant);
  const theme = useTheme();

  const handleSelectedChanges = useCallback(
    (statuses: IncidentStatus[]) => {
      const flattenedStatusArray = statuses.flat();
      const uniqueStatusArray = flattenedStatusArray.filter(
          (value, index, self) => index === self.indexOf(value) && self.lastIndexOf(value) === index);
      updateFilter({ incidentStatus: uniqueStatusArray});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (variant === FaultStateVariant.INAPPROVALVIEW) {
      handleSelectedChanges([IncidentStatus.ApprovalRequiredByCommune]);
    }
  }, [variant, handleSelectedChanges]);

  function getStatusForView(variant: FaultStateVariant): StatusGroup[] {
    const statusGroups: StatusGroup[] = [];

    switch (variant) {
      case FaultStateVariant.INPROGRESSVIEW:
        statusGroups.push(...getInProgressStatusGroups());
        break;
      case FaultStateVariant.COMPLETEDVIEW:
        statusGroups.push(...getCompletedStatusGroups());
        break;
      case FaultStateVariant.INAPPROVALVIEW:
        statusGroups.push({ label: "Freigabe erforderlich", statuses: [IncidentStatus.ApprovalRequiredByCommune]});
        break;
      default:
        break;
    }

    return statusGroups;
  }

  return (
    <Paper className="mb-8 p-5">
      <Box className="flex flex-col gap-7">
        <Box className="flex justify-between gap-4">
          <Box className="flex flex-col gap-4">
            <AsyncTextField
              label="Leucht-/Schaltstelle"
              variant="standard"
              value={filter.lightingNo}
              onAsyncChange={(lightingNo) => {
                updateFilter({ lightingNo: lightingNo.trim() });
              }}
              fullWidth
            />
            <Box className="flex gap-3">
              <AsyncTextField
                label="Straße"
                variant="standard"
                value={filter.street}
                onAsyncChange={(street) => {
                  updateFilter({ street: street.trim() });
                }}
                fullWidth
              />
              <AsyncTextField
                label="Hausnr."
                variant="standard"
                value={filter.houseNo}
                onAsyncChange={(houseNo) => {
                  updateFilter({ houseNo: houseNo.trim() });
                }}
              />
            </Box>
          </Box>

          <Box className="flex flex-col gap-4 max-w-md flex-1">
            <FormControl fullWidth>
              <InputLabel id="status-select">Bearbeitungsstatus</InputLabel>
              <Select
                disabled={variant === FaultStateVariant.INAPPROVALVIEW}
                multiple
                labelId="status-select"
                value={filter.incidentStatus}
                onChange={(event) => handleSelectedChanges(event.target.value as IncidentStatus[])}
                renderValue={(selected) => {
                  const selectedLabels = selected.reduce((acc, status) => {
                    const group = Object.values(getStatusForView(variant)).find((g) => g.statuses.includes(status));
                    if (group && !acc.includes(group.label)) {
                      acc.push(group.label);
                    }
                    return acc;
                  }, [] as string[]);
                  return selectedLabels.join(', ');
                }}
              >
                {Object.values(getStatusForView(variant)).map((group) => (
                  <MenuItem style={getStyles(group.statuses, filter.incidentStatus, theme)} key={group.label} value={group.statuses}>
                    { group.label }
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="flex gap-3 max-w-xs">
            <DatePicker
              label="Von"
              mask={"__.__.____"}
              value={filter.dateFrom || null}
              disableFuture
              onChange={(date) => {
                date
                  ? updateFilter({ dateFrom: startOfDay(new Date(date)).toISOString() })
                  : updateFilter({ dateFrom: undefined });
              }}
              renderInput={(params) => <TextField variant="standard" {...params} />}
            />
            <DatePicker
              label="Bis"
              mask={"__.__.____"}
              value={filter.dateTo || null}
              disableFuture
              onChange={(date) => {
                date
                  ? updateFilter({ dateTo: endOfDay(new Date(date)).toISOString() })
                  : updateFilter({ dateTo: undefined });
              }}
              renderInput={(params) => <TextField variant="standard" {...params} />}
            />
          </Box>
        </Box>
        <Button color="secondary" size="small" onClick={() => resetFilters()} className="self-end">
          Alle Filter zurücksetzen
        </Button>
      </Box>
    </Paper>
  );
}

function useResetFetchFilters(view: FaultStateVariant) {
  const { updateFilter } = useFaultReportContext();

  const resetFilters = () => {
    updateFilter({
      lightingNo: undefined,
      priority: undefined,
      street: undefined,
      houseNo: undefined,
      incidentStatus: [],
      dateFrom: undefined,
      dateTo: undefined,
    });
  };

  return resetFilters;
}
